import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import { Channel } from 'src/types/channel';
import { ChannelView, ChannelViewTab } from 'src/types/channelView';
import MobilePublicHeader from './MobilePublicHeader';
import MobileSearchBar from './MobileSearchBar';
import MobileContent from './MobileContent';
import makeStyles from '@mui/styles/makeStyles';
import { getWSConfig } from 'src/views/utils/Utils';
import { Media } from 'src/types/media';
import MobilePublicHeaderBar from './MobilePublicHeaderBar';

interface MobileViewProps {
    channel: Channel;
    channelView: ChannelView;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    container: {
        minHeight: '100vh',
        width: '100%'
    },
    circularProgress: {
        marginTop: '50%'
    }
}));

const MobileView: FC<MobileViewProps> = ({ channel, channelView }) => {
    const [query, setQuery] = useState<string>('');
    const [isContentLoaded, setContentLoaded] = useState<boolean>(true)
    const classes = useStyles();
    const config = getWSConfig();
    const tabsLoaded: Map<number, boolean> = new Map<number, boolean>()

    if (channelView) {
        if (isContentLoaded) {
            return (
                <>
                    <MobilePublicHeaderBar channelView={channelView} channel={channel}></MobilePublicHeaderBar> 
                    <Container maxWidth="md" className={classes.container}>
                        <Box>
                            <Box mt={2}>
                                <MobilePublicHeader channelView={channelView}></MobilePublicHeader>
                            </Box>
                            <Box>
                                <MobileSearchBar setQuery={setQuery}></MobileSearchBar>
                            </Box>
                            <Box mt={2}>
                                <MobileContent setContentLoaded={setContentLoaded} channelView={channelView} channel={channel} query={query}></MobileContent>
                            </Box>
                        </Box>
                    </Container>
                </>

            );
        } else {
            return (
                <Box display="flex" justifyContent="center">
                    <CircularProgress size={300} className={classes.circularProgress}></CircularProgress>
                </Box>
            )
        }
    } else return null
}

export default MobileView;