import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Box, Hidden, IconButton, Toolbar, SvgIcon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Menu as MenuIcon } from 'react-feather';
import Account from './Account';
import Settings from './Settings';
import { ProfessionalMedic } from 'src/types/professionalMedic';
import axios from 'axios';
import AlternativeLogout from './AlternativeLogout';
import Logo from 'src/components/Logo';

interface TopBarProps {
    className?: string;
    onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.appBar,
        backgroundColor: '#ffffff'
    },
    toolbar: {
        minHeight: 64
    }
}));

const TopBar: FC<TopBarProps> = ({
    className,
    onMobileNavOpen,
    ...rest
}) => {
    const classes = useStyles();
    const professionalMedic: ProfessionalMedic = JSON.parse(localStorage.getItem('user'));
    const [organizationLogo, setOrganizationLogo] = useState<string>(window.localStorage.getItem('organization_logo'));
    const [loading, setLoading] = useState<boolean>(true);
    const alternativeLogout = localStorage.getItem('alternativeLogout');

    const storeOrganizationLogo = ((logo: string) => {
        setOrganizationLogo(logo);
        window.localStorage.setItem('organization_logo', logo);
    })

    useEffect(() => {
        if (!organizationLogo) {
            axios.get(process.env.REACT_APP_SERVER_URL + '/professional_medic/1.0/get/organization/logo/by/professional/medic')
                .then(function (response) {
                    storeOrganizationLogo(response.data)
                    setLoading(false)
                })
                .catch(function (error) {
                    storeOrganizationLogo('https://logos-canals.s3.eu-west-1.amazonaws.com/Logo+Digimevo.png')
                    setLoading(false)
                })
        }
        else {
            setLoading(false);
        }
    }, []);

    if (loading === false) {
        return (
            <AppBar
                className={clsx(classes.root, className)}
                sx={{ displayPrint: 'none' }}
                {...rest}
            >
                <Toolbar className={classes.toolbar}>
                    <Hidden lgUp>
                        <IconButton color="primary" onClick={onMobileNavOpen} size="large">
                            <SvgIcon fontSize="small">
                                <MenuIcon />
                            </SvgIcon>
                        </IconButton>
                    </Hidden>
                    <Hidden lgDown>
                        {alternativeLogout === 'true' ? (
                            <Box style={{ cursor: 'default', width: '10rem' }}>
                                <Logo />
                            </Box>
                        ) : (
                            <RouterLink to={"/login/" + professionalMedic.organization_id}>
                                <Box style={{ cursor: 'default', width: '10rem' }}>
                                    <Logo />
                                </Box>
                            </RouterLink>

                        )}
                    </Hidden>
                    <Box
                        ml={2}
                        flexGrow={1}
                    />
                    {alternativeLogout === 'true' ? <AlternativeLogout /> : <Settings />}
                    <Box ml={2}>
                        <Account />
                    </Box>
                </Toolbar>
            </AppBar>
        );
    }
    else {
        return null;
    }

};

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
    onMobileNavOpen: () => { }
};

export default TopBar;
