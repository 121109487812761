import React, { useState } from 'react';
import type { FC } from 'react';
import { SvgIcon, Box, Typography, IconButton, Card, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { ChannelView } from 'src/types/channelView';
import {
    Video as VideoIcon,
    PlayCircle as PlayCircleIcon,
    MoreVertical as MoreVerticalIcon
} from 'react-feather';
import { Media } from 'src/types/media';
import MobileVideoPlayerModal from './MobileVideoPlayerModal';
import Label from 'src/components/Label';
import MobileMoreOptionsDrawer from './MobileMoreOptionsDrawer';

interface MobileDrawerVideoCardProps {
    video: Media;
    channelView: ChannelView;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        title: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            height: '2.5rem',
            wordBreak: 'break-word'
        },
        videoIcon: {
            color: theme.palette.text.secondary
        },
        playCircleIcon: {
            position: 'absolute',
            pointerEvents: 'none',
            top: "50%",
            left: "50%",
            transform: "Translate(-50%,-50%)",
            width: '2rem',
            color: 'white',
            cursor: 'pointer',
        },
        labelTime: {
            position: 'absolute',
            top: "85%",
            left: "75%",
            transform: "Translate(-50%,-50%)",
            color: theme.palette.grey[50],
            background: theme.palette.text.primary
        },
        moreIcon: {
            width: '16px',
            height: '16px',
        },
        iconButton: {
            padding: 0,
        },
        multiLineEllipsis: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical"
        }
    }),
);

const MobileDrawerVideoCard: FC<MobileDrawerVideoCardProps> = ({ video, channelView }) => {
    const classes = useStyles();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isMoreOptionsOpen, setMoreOptionsOpen] = useState<boolean>(false);

    const closeModal = (): void => {
        setModalOpen(false)
    }

    const openModal = (): void => {
        setModalOpen(true)
    }

    const switchMoreOptions = (): void => {
        if (!isMoreOptionsOpen) setMoreOptionsOpen(true)
        else setMoreOptionsOpen(false)
    }

    return (
        <Box position="relative">
            {isMoreOptionsOpen && <MobileMoreOptionsDrawer setMoreOptionsOpen={setMoreOptionsOpen} channelView={channelView} video={video}></MobileMoreOptionsDrawer>}
            <MobileVideoPlayerModal channelView={channelView} isOpen={isModalOpen} onClose={closeModal} media={video}></MobileVideoPlayerModal>
            <Card sx={{ display: 'flex' }} >
                <Box flex="50%" padding={2}>
                    <Box display="flex" gap={1}>
                        <Box>
                            <SvgIcon
                                fontSize="small"
                            >
                                <VideoIcon className={classes.videoIcon} />
                            </SvgIcon>
                        </Box>
                        <Box>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                Video
                            </Typography>
                        </Box>
                        <Box marginLeft="auto">
                            <IconButton onClick={switchMoreOptions} aria-label="settings" size="small" className={classes.iconButton} >
                                <MoreVerticalIcon className={classes.moreIcon} />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box position="relative">
                        <CardMedia
                            component="img"
                            height="90"
                            image={video.big_thumbnailUrl}
                            onClick={openModal}
                        />
                        <SvgIcon className={classes.playCircleIcon}
                            fontSize="large"
                            color="action"
                        >
                            <PlayCircleIcon />
                        </SvgIcon>
                        <Label
                            className={classes.labelTime}
                        >
                            <Typography variant="caption">
                                {video.duration}
                            </Typography>
                        </Label>
                    </Box>
                </Box>
                <Box flex="50%" padding={2} display="flex" flexDirection="column" gap={1} mt={1}>
                    <Box>
                        <Typography component="div" variant="h6" className={classes.title}>
                            {video.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="body2" color="text.secondary" component="div" className={classes.title}>
                            {video.description}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <IconButton aria-label="play/pause">
                        </IconButton>
                    </Box>
                </Box>
            </Card>
        </Box>

    );
}

export default MobileDrawerVideoCard;