import React from 'react';
import type { FC } from 'react';
import { Box, IconButton, SvgIcon, Typography, Drawer, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ChevronLeft as ArrowLeftIcon,
} from 'react-feather';
import { Channel } from 'src/types/channel';
import { ChannelView } from 'src/types/channelView';


interface MobileLiveDrawerProps {
    isOpen?: boolean;
    onClose?: () => void;
    liveEntryId: string;
    channelView: ChannelView;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    drawer: {
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.background.dark
    },
    arrowLeft: {
        paddingLeft: 0

    },
    liveIcon: {
        backgroundColor: theme.palette.secondary.main,
        width: '45px',
        height: '20px',
        borderRadius: '2px',
        textAlign: 'center',
        marginTop: '2rem'
    },
    liveText: {
        color: theme.palette.grey[50],
        fontFamily: 'roboto'
    },
}));

const MobileLiveDrawer: FC<MobileLiveDrawerProps> = ({
    isOpen,
    onClose,
    liveEntryId,
    channelView
}) => {
    const classes = useStyles();

    const closeDrawer = (): void => {
        onClose()
    }

    return (
        <Drawer
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={isOpen}>
            <Container>
                <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                    <IconButton className={classes.arrowLeft} onClick={closeDrawer}>
                        <SvgIcon >
                            <ArrowLeftIcon />
                        </SvgIcon>
                    </IconButton>
                    <Typography
                        variant="body2"
                        color='textSecondary'>
                        {channelView.channel_name}
                    </Typography>
                </Box>
                <Box mt={3}>
                    <Typography
                        variant="h3"
                        color='textPrimary'>
                        Live
                    </Typography>
                    <Box className={classes.liveIcon}>
                        <Box>
                            <Typography variant="h6" className={classes.liveText}>
                                LIVE
                            </Typography>
                        </Box>
                    </Box>
                    <Box marginTop="1rem">
                        <Typography
                            variant="body2"
                            color='textSecondary'>
                            View live MDB sessions
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <iframe
                        id="kaltura_player_1617182903"
                        src={"https://cdnapisec.kaltura.com/p/2420952/sp/242095200/embedIframeJs/uiconf_id/47439093/partner_id/2420952?iframeembed=true&playerId=kaltura_player_1617183208&entry_id=" + liveEntryId}
                        style={{ width: '100%', height: '100%', paddingBottom: '2.5rem', paddingTop: '2.5rem', paddingRight: '1.5rem', paddingLeft: '2.5rem' }}
                        allowFullScreen={true}
                        frameBorder="0"
                        itemProp="video"
                        itemScope
                        itemType="http://schema.org/VideoObject"
                    >
                    </iframe>

                </Box>
            </Container>
        </Drawer>
    );
};

export default MobileLiveDrawer;