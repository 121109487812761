import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, IconButton, DialogContent, SvgIcon, Typography, Drawer, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ChevronLeft as ArrowLeftIcon,
} from 'react-feather';
import MobileSearchBar from './MobileSearchBar';
import { ChannelView, ChannelViewSection, ChannelViewTab } from 'src/types/channelView';
import { Channel } from 'src/types/channel';
import MobileSubsectionContent from './MobileSubsectionContent';


interface MobileSectionDrawerProps {
    isOpen?: boolean;
    onClose?: () => void;
    section: ChannelViewSection;
    player_uiConfid: string;
    channelView: ChannelView;
    channel: Channel;
    tabName: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    drawer: {
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.background.dark
    },
    arrowLeft: {
        paddingLeft: 0
    },
}));

const MobileSectionDrawer: FC<MobileSectionDrawerProps> = ({
    isOpen,
    onClose,
    section,
    player_uiConfid,
    channelView,
    channel,
    tabName
}) => {
    const classes = useStyles();
    const closeDrawer = (): void => {
        onClose()
    }
    const [query, setQuery] = useState<string>('');

    return (
        <Drawer
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={isOpen}>
            <Container>
                <Box display="flex" flexDirection="column" gap={3}>
                    <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                        <IconButton className={classes.arrowLeft} onClick={closeDrawer}>
                            <SvgIcon >
                                <ArrowLeftIcon />
                            </SvgIcon>
                        </IconButton>
                        <Typography
                            variant="body2"
                            color='textSecondary'>
                            {tabName}
                        </Typography>
                    </Box>
                    <Box display="flex" mt={2}>
                        <Typography variant="h3" color="textPrimary">
                            {section.name}
                        </Typography>
                    </Box>
                    <MobileSearchBar setQuery={setQuery}></MobileSearchBar>
                    <Box display="flex" flexDirection="column" gap={4}>
                        {section.channelViewSubSectionList.map((subsection, index) => {
                            return (
                                <MobileSubsectionContent channelView={channelView} channel={channel} key={index} subsection={subsection} sectionName={section.name} player_uiConfid={player_uiConfid} query={query}></MobileSubsectionContent>
                            )
                        })}
                    </Box>

                </Box>

            </Container>
        </Drawer>
    );
};

export default MobileSectionDrawer;