import React, { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import NavBar from './NavBar';
import TopBar from './TopBar';
import { WebSocketProvider } from 'src/contexts/WebSocketSFUContext';

interface DashboardLayoutProps {
    children?: ReactNode
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    wrapperWhitoutNavBar: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
    const showNavBar = localStorage.getItem('showNavBar') === 'true';
    const leftPaddingStyle = showNavBar ? classes.wrapper : classes.wrapperWhitoutNavBar;
    console.log(!localStorage.getItem('showNavBar'));
    return (
        <div className={classes.root}>

            {!localStorage.getItem('showTopBar') || localStorage.getItem('showTopBar') === "true" && (
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            )}

            {!localStorage.getItem('showNavBar') || localStorage.getItem('showNavBar') === "true" ? (
                < WebSocketProvider >
                    <NavBar
                        onMobileClose={() => setMobileNavOpen(false)}
                        openMobile={isMobileNavOpen}
                        displace={localStorage.getItem('showTopBar') === 'false'}
                    />
                    <div className={leftPaddingStyle}>
                        <div className={classes.contentContainer}>
                            <div className={classes.content}>
                                {children}
                            </div>
                        </div>
                    </div>
                </WebSocketProvider>
            ) : (
                <WebSocketProvider>
                    <div className={leftPaddingStyle}>
                        <div className={classes.contentContainer}>
                            <div className={classes.content}>
                                {children}
                            </div>
                        </div>
                    </div>
                </WebSocketProvider>
            )
            }

        </div >
    );
};

DashboardLayout.propTypes = {
    children: PropTypes.node
};

export default DashboardLayout;
