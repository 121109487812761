import { useState, type FC, useEffect } from 'react';
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, SvgIcon, Typography, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    File as FileIcon,
} from 'react-feather';
import { getWSConfig } from 'src/views/utils/Utils';
import axios from 'axios';
import { FileResource } from 'src/types/file_resource';

interface MobileFileBoxProps {
    file: FileResource;
}

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding: 1rem;
  }
`);

const useStyles = makeStyles((theme) => ({
    root: {
        flexBasis: '80%',
        flexShrink: 0
    },
    boxIcon: {
        position: 'relative',
        color: theme.palette.text.secondary,
        float: 'left',
    },
    titleFile: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2.5rem',
        wordBreak: 'break-word'
    },
    boxFileIcon: {
        position: 'relative',
        display: "flex",
        backgroundColor: theme.palette.neutral[400],
        aspectRatio: '16 / 9',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
        cursor: 'pointer',
        width: '100%',
        height: 'auto',
    },
    fileIcon: {
        width: '5rem',
        height: '5rem',
        color: theme.palette.primary.contrastText,
    },
    card: {
        marginLeft: 2,
        marginRight: 2,
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
    },
    cardActions: {
        padding: 0,
        marginTop: '1rem',
        position: 'relative'
    },
    cardHeader: {
        '&.MuiCardHeader-root': {
            padding: 0
        }
    },
    chip: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: 'white',
        cursor: 'pointer',
    },
    image: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.dark,
    },
}));


const MobileFileBox: FC<MobileFileBoxProps> = ({ file }) => {
    const classes = useStyles();
    const config = getWSConfig();

    const getFileLink = (): void => {
        let params = {}
        params["file_id"] = file.file_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/file_resource/1.0/get/file/link/public', params, config)
            .then(function (response) {
                if (response && response.data) {
                    window.open(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    };

    const openFileLink = (): void => {
        getFileLink();
    };

    const [thumbnailFileLink, setThumbnailFileLink] = useState(null);
    const getThumbnailFileLink = (): void => {
        let params = {}
        params["file_id"] = file.file_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/file_resource/1.0/public/get/thumbnail_link', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setThumbnailFileLink(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
                return null;
            });
    };
    useEffect(() => {
        if (file.thumbnail_extension) getThumbnailFileLink();
    }, []);

    return (
        <Box className={classes.root}>
            <Card className={classes.card}>
                <Box position="relative">
                    <Box
                        onClick={() => openFileLink()}
                        maxHeight={140}
                        className={classes.boxFileIcon}>
                        {(file.thumbnail_extension) && <CardMedia className={classes.image}
                            image={thumbnailFileLink}
                        >
                        </CardMedia>}
                        {(!file.thumbnail_extension) && (file.thumbnail_url) && <CardMedia className={classes.image}
                            image={file.thumbnail_url}
                        >
                        </CardMedia>}
                        {(!file.thumbnail_extension) && (!file.thumbnail_url) && <Box className={classes.boxFileIcon}> 
                            <FileIcon className={classes.fileIcon} />
                            <Chip
                                label={file.extension}
                                color='primary'
                                className={classes.chip}
                                size='small'
                            />
                        </Box>}
                    </Box>
                    <Chip
                        label={file.extension}
                        color='primary'
                        className={classes.chip}
                        size='small'
                    />
                </Box>
                <CardContentNoPadding sx={{ position: 'relative' }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={<Typography
                            variant="h6"
                            color="textPrimary"
                            className={classes.titleFile}
                        >
                            {file.title}
                        </Typography>}
                    />
                    <CardActions className={classes.cardActions}>
                        <Box display="flex">
                            <Box>
                                <SvgIcon
                                    fontSize="small"
                                >
                                    <FileIcon className={classes.boxIcon} />
                                </SvgIcon>

                            </Box>
                            <Box ml={1.5}>
                                <Typography
                                    color="textSecondary"
                                    variant="h6"
                                >
                                    {"File"}
                                </Typography>
                            </Box>
                        </Box>
                    </CardActions>
                </CardContentNoPadding>
            </Card>
        </Box>
    );
};

export default MobileFileBox;
