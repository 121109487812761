import React, { FC, useCallback, useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SubscriptionButton from '../../utils/SubscriptionButton';
import type { Channel } from 'src/types/channel';
import { FormattedMessage } from 'react-intl';
import { ChannelViewInfoType } from 'src/types/channelView';

interface PublicHeaderProps {
    channel: Channel,
    channelViewInfo: ChannelViewInfoType
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    roundedCenter: {
        width: "120px",
        height: "120px",
        position: "absolute",
        display: "block",
        transform: "translateY(-50%) translateX(-50%)",
        background: "#fff",
        borderRadius: "50%",
        marginLeft: "6.25rem",
        marginTop: "-15px"
    },
    toolbar: {
        minHeight: 128,
        alignItems: 'flex-start',
        paddingBottom: theme.spacing(2),
        height: '14rem',
    },
    toolbarBottom: {
        height: '4.5rem',

    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    channelsDiv: {
        textAlign: "center",
        marginLeft: "2rem"
    },
    channelsText: {
        textAlign: "left",
        marginLeft: "8.8rem"
    },
    subscribeButtonDiv: {
        textAlign: "right",
        marginRight: "2em",
        marginTop: "0.5em",
    },
    roundedDiv: {
        position: "relative"
    },
    channelImage: {
        width: "100%",
        height: "100%",
        display: "inline-block",
        backgroundSize: "cover",
        borderRadius: "50%",
        border: "2px solid #ddd"
    },
    headerImage: {
        width: "100%",
        height: "100%"
    }
}));

const PublicHeader: FC<PublicHeaderProps> = ({
    channel,
    channelViewInfo
}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.toolbar}>
                <img className={classes.headerImage} src={channel.cover_photo_link} alt="Logo" />
            </div>
            <div className={classes.roundedDiv}>
                <div className={classes.roundedCenter}>
                    {channelViewInfo && channelViewInfo.web_url &&
                        <a href={channelViewInfo.web_url} target="_blank">
                            <img
                                className={classes.channelImage}
                                alt="ChannelLogo"
                                src={channel.logo}
                            />
                        </a>
                    }
                    {channelViewInfo && !channelViewInfo.web_url &&
                        <img
                            className={classes.channelImage}
                            alt="ChannelLogo"
                            src={channel.logo}
                        />
                    }
                    {!channelViewInfo  &&
                        <img
                            className={classes.channelImage}
                            alt="ChannelLogo"
                            src={channel.logo}
                        />
                    }
                </div>
            </div>
            <div className={classes.toolbarBottom}>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <div className={classes.channelsDiv}>
                                        <div className={classes.channelsText}>
                                            <Typography component="div">
                                                <Box fontSize="11px" letterSpacing={1} fontFamily="Inter, sans-serif" color='#535353'>
                                                    <FormattedMessage id="Channel_PublicChannelDetailView_PublicHeader.channels" />
                                                </Box>
                                                <Box fontWeight="fontWeightBold" fontSize="h4.fontSize" letterSpacing={0.5} fontFamily="Inter, sans-serif">
                                                    {channel.name}
                                                </Box>
                                            </Typography>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={4} />
                </Grid>
            </div >
        </div>
    );
}
export default PublicHeader;