import React, {
    useRef,
    useState
} from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Avatar from 'react-avatar';
import { Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ProfessionalMedic } from 'src/types/professionalMedic';
import useAuth from 'src/hooks/useAuth';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1)
    },
    popover: {
        width: 200
    }
}));

const Account: FC = () => {
    const classes = useStyles();
    const professionalMedic: ProfessionalMedic = JSON.parse(localStorage.getItem('user'));


    let name = "";
    let last_name = "";

    if (professionalMedic) {
        name = professionalMedic.name;
    }
    if (professionalMedic) {
        last_name = professionalMedic.last_name;
    }

    if (name) {
        return <>
            <Box
                display="flex"
                alignItems="center"
            // @ts-ignore
            >
                <Avatar
                    name={name + " " + last_name}
                    maxInitials={2}
                    size="30"
                    round={true}
                    color="#E0E0E0"
                    className={classes.avatar}
                />
                <Hidden mdDown>
                    <Typography
                        variant="h6"
                        color="textPrimary"
                    >
                        {name + " " + last_name}
                    </Typography>
                </Hidden>
            </Box>
        </>;
    }
    else {
        return null;
    }
}

export default Account;
