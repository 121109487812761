import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, IconButton, DialogContent, SvgIcon, Typography, Drawer, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ChevronLeft as ArrowLeftIcon,
} from 'react-feather';
import MobileSearchBar from './MobileSearchBar';
import { ChannelView, ChannelViewTab } from 'src/types/channelView';
import MobileSectionContent from './MobileSectionContent';
import { Channel } from 'src/types/channel';


interface MobileTabDrawerProps {
    isOpen?: boolean;
    onClose?: () => void;
    tab: ChannelViewTab;
    player_uiConfid: string;
    channelView: ChannelView;
    channel: Channel;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    drawer: {
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.background.dark
    },
    arrowLeft: {
        paddingLeft: 0
    },
}));

const MobileTabDrawer: FC<MobileTabDrawerProps> = ({
    isOpen,
    onClose,
    tab,
    player_uiConfid,
    channelView,
    channel
}) => {
    const classes = useStyles();
    const closeDrawer = (): void => {
        onClose()
    }
    const [query, setQuery] = useState<string>('');

    return (
        <Drawer
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={isOpen}>
            <Container>
                <Box display="flex" flexDirection="column" gap={3}>
                    <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                        <IconButton className={classes.arrowLeft} onClick={closeDrawer}>
                            <SvgIcon >
                                <ArrowLeftIcon />
                            </SvgIcon>
                        </IconButton>
                        <Typography
                            variant="body2"
                            color='textSecondary'>
                            {channelView.channel_name}
                        </Typography>
                    </Box>
                    <Box display="flex" mt={2}>
                        <Typography variant="h3" color="textPrimary">
                            {tab.name}
                        </Typography>
                    </Box>
                    <MobileSearchBar setQuery={setQuery}></MobileSearchBar>
                    <Box display="flex" flexDirection="column" gap={4}>
                        {tab.channelViewSectionList.map((section, index) => {
                            return (
                                <MobileSectionContent channelView={channelView} channel={channel} key={index} section={section} tabName={tab.name} player_uiConfid={player_uiConfid} query={query}> isSection={true}</MobileSectionContent>
                            )
                        })}
                    </Box>

                </Box>

            </Container>
        </Drawer>
    );
};

export default MobileTabDrawer;