import React, { useState } from 'react';
import type { FC } from 'react';
import {
    Menu,
    IconButton,
    SvgIcon,
    MenuItem,
    Box,
    Hidden,
    Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import {
    Globe as GlobeIcon,
} from 'react-feather';

import PropTypes from 'prop-types';
import useSettings from 'src/hooks/useSettings';
import { FormattedMessage } from 'react-intl';

interface MobileLanguageSelectionProps {
    className?: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    arrowButton: {
        marginLeft: '-0.5rem',
        marginTop: '-0.5rem'
    },
    primary:{
        color: theme.palette.primary.main
    }
}));

const MobileLanguageSelection: FC<MobileLanguageSelectionProps> = ({ className, ...rest }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { settings, saveSettings } = useSettings();
    const classes = useStyles();
    const [values, setValues] = useState({
        language: settings.language
    });
    

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSwitchLanguage = (new_language) => {
        setAnchorEl(null);
        saveSettings({
            ...values,
            language: new_language,
        });
    };

    const getLanguageString = (language:string ): any => {
        if (language == "Español") {
            if (settings.language == "es") return <Typography className={classes.primary}>{language}</Typography>
            else return <Typography component={'span'}>{language}</Typography>
        }
        else {
            if (settings.language == "en") return <Typography className={classes.primary}>{language}</Typography>
            else return <Typography component={'span'}>{language}</Typography>
        }
    }

    return (
        <Box>
            <IconButton
                onClick={handleClick}
            >
                <SvgIcon sx={{ fontSize: 25 }}>
                    <GlobeIcon />
                </SvgIcon>
            </IconButton>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleSwitchLanguage('es')}>
                    <Typography component={'span'}>
                        {getLanguageString("Español")}
                    </Typography>
                </MenuItem>
                <MenuItem onClick={() => handleSwitchLanguage('en')}>
                    <Typography component={'span'}>
                        {getLanguageString("English")}
                    </Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};

MobileLanguageSelection.propTypes = {
    className: PropTypes.string,
};

export default MobileLanguageSelection;