import { FormattedMessage, useIntl } from 'react-intl';
import React from 'react'

export function anatomySpecialityFilterOptions() {
    const intl = useIntl();
    return [
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.regions' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.systems' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.complete' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.sections' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.allergy' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.cardiology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.odontology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.dermatology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.endocrinology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.gastroenterology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.infectious' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.nephrology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.neurology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.obstetrics' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.oncology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.ophthalmology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.orthopedics' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.otorhinolaryngology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.pediatrics' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.pneumology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.rheumatology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.AnatomySpeciality_filter.urology' })
    ];
}

export function typeFilterOptions() {
    const intl = useIntl();
    return [
        intl.formatMessage({ id: 'Models.ModelsListView.Models.TypeFilter_filter.anatomy' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.TypeFilter_filter.disease' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.TypeFilter_filter.physology' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.TypeFilter_filter.procedure' })
    ];
}

export function levelOfDetailFilterOptions() {
    const intl = useIntl();
    return [
        intl.formatMessage({ id: 'Models.ModelsListView.Models.LevelOfDetail_filter.gross' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.LevelOfDetail_filter.tissue' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.LevelOfDetail_filter.cellular' }),
        intl.formatMessage({ id: 'Models.ModelsListView.Models.LevelOfDetail_filter.molecular' })
    ];
}

export const numberOfSubcriptionsInLeftMenu = 4;