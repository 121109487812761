import { useState } from 'react';
import type { FC } from 'react';
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, SvgIcon, Typography, styled } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Box as BoxIcon,
} from 'react-feather';
import { Model } from 'src/types/model';
import { Channel } from 'src/types/channel';
import { FormattedMessage } from 'react-intl';
import ModelBlockModalSuscribe from '../ModelBlockModalSuscribe';

interface MobilePublicModelBoxProps {
    className?: string;
    singleModel?: Model;
    channel: Channel;
}

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding: 1rem;
  }
`);


const useStyles = makeStyles((theme) => ({
    root: {
        flexBasis: '80%',
        flexShrink: 0
    },
    boxIcon: {
        position: 'relative',
        color: theme.palette.text.secondary,
        float: 'left',
    },
    titleFile: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2.5rem',
        wordBreak: 'break-word'
    },
    card: {
        marginLeft: 2,
        marginRight: 2,
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
    },
    cardActions: {
        padding: 0,
        marginTop: '1rem',
        position: 'relative'
    },
    cardHeader: {
        '&.MuiCardHeader-root': {
            padding: 0
        }
    },
    imageWithoutAccess: {
        height: '140',
        width: '100%',
        aspectRatio: '16 / 9',
    },
}));


const MobilePublicModelBox: FC<MobilePublicModelBoxProps> = ({ className, singleModel, channel }) => {
    const [isOpenlSuscribeDialog, setOpenSuscribeDialog] = useState<boolean>(false);
    const classes = useStyles();

    const handleOpenSuscribeDialog = (): void => {
        setOpenSuscribeDialog(true);
    };


    return (
        <Box className={classes.root}>
            <Card className={classes.card}>
                <Box position="relative">
                    <CardMedia
                        onClick={() => handleOpenSuscribeDialog()}
                        component="img"
                        height="140"
                        image={singleModel.thumbnail_url}
                    />

                </Box>
                <CardContentNoPadding sx={{ position: 'relative' }}>
                    <CardHeader
                        className={classes.cardHeader}
                        title={<Typography
                            variant="h6"
                            color="textPrimary"
                            className={classes.titleFile}
                        >
                            {singleModel.title}
                        </Typography>}
                    />
                    <CardActions className={classes.cardActions}>
                        <Box display="flex">
                            <Box>
                                <SvgIcon
                                    fontSize="small"
                                >
                                    <BoxIcon className={classes.boxIcon} />
                                </SvgIcon>
                            </Box>
                            <Box ml={1.5}>
                                <Typography
                                    color="textSecondary"
                                    variant="h6"
                                >
                                    <FormattedMessage id="Channel_ChannelDetailView_ModelBox.3d_model" />

                                </Typography>
                            </Box>
                        </Box>
                    </CardActions>
                </CardContentNoPadding>
                {isOpenlSuscribeDialog &&
                    <ModelBlockModalSuscribe channel={channel} isOpen={isOpenlSuscribeDialog} setOpen={setOpenSuscribeDialog} />
                }
            </Card>
        </Box>
    );
};

export default MobilePublicModelBox;
