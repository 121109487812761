import React from 'react';
import createStyles from '@mui/styles/createStyles';
import type { FC } from 'react';
import PeopleAltOutlinedIcon from '@mui/icons-material/Login';
import { useHistory } from 'react-router-dom';
import { Button, SvgIcon, AppBar, Toolbar, Hidden, Box, Stack } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChannelView } from 'src/types/channelView';
import PublicLogo from 'src/components/PublicLogo';
import { Channel } from 'src/types/channel';
import { FormattedMessage } from 'react-intl';
import LanguageSelection from 'src/views/auth/LanguageSelection';
import MobileLanguageSelection from './MobileLanguageSelection';
import { Link } from 'react-router-dom';

interface MobilePublicHeaderBarProps {
    channelView: ChannelView;
    channel: Channel;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        loginButton: {
            backgroundColor: theme.palette.background.default,
            color: '#535961'
        },
        appBar: {
            backgroundColor: theme.palette.background.default
        }
    }),
);

const MobilePublicHeaderBar: FC<MobilePublicHeaderBarProps> = ({ channelView, channel }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar >
                <PublicLogo channel={channel} channelViewInfo={channelView.channelViewInfo} />

                    <Stack direction={"row"} marginLeft="auto" gap={1}>
                        <Box>
                            <Button
                                href="https://www.pro.digimevo.com"
                                className={classes.loginButton}
                                startIcon={
                                    <SvgIcon fontSize="small">
                                        <PeopleAltOutlinedIcon />
                                    </SvgIcon>
                                }
                            >
                                <FormattedMessage id="Channel_PublicChannelDetailView_PublicHeaderBar.enter" />
                            </Button>
                        </Box>
                        <Box >
                            <Button
                                href="https://www.pro.digimevo.com/register"
                                variant="contained"
                            >
                                <FormattedMessage id="Channel_PublicChannelDetailView_PublicHeaderBar.register" />
                            </Button>
                        </Box>
                        <Box>
                            <MobileLanguageSelection />
                        </Box>
                    </Stack>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default MobilePublicHeaderBar;