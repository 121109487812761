import React, {
    useState,
    useRef
} from 'react';
import type { FC } from 'react';
import {
    Badge,
    Box,
    Button,
    IconButton,
    Popover,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom'


const useStyles = makeStyles((theme) => ({
    popover: {
        width: 320,
        padding: theme.spacing(2)
    }
}));

const Settings: FC = () => {
    const classes = useStyles();
    const ref = useRef<any>(null);
    const { settings, saveSettings } = useSettings();
    const [isOpen, setOpen] = useState<boolean>(false);
    const [values, setValues] = useState({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme
    });

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChange = (field, value): void => {
        setValues({
            ...values,
            [field]: value
        });
    };

    const handleSave = (): void => {
        saveSettings(values);
        setOpen(false);
    };

    return <>
        <Tooltip title={<FormattedMessage id="settings_topbar_Tooltip.configuration" />}>
            <Badge
                color="secondary"
            >
                <Route render={({ history }) => (
                    <IconButton
                        color="default"
                        onClick={() => { history.push('/app/account') }}
                        ref={ref}
                        size="large">
                        <SvgIcon fontSize="small">
                            <SettingsIcon />
                        </SvgIcon>
                    </IconButton>
                )} />
            </Badge>
        </Tooltip>
    </>;
}

export default Settings;