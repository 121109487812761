import React from 'react';
import type { FC } from 'react';
import { Box, SvgIcon, Typography } from '@mui/material';

interface MobileInformationRowProps {
    text: string;
    icon: any;
}

const MobileInformationRow: FC<MobileInformationRowProps> = ({
    text,
    icon

}) => {
    return (
        <Box display="flex" gap={6}>
            <SvgIcon>
                {icon}
            </SvgIcon>
            <Typography variant="body2" color="textPrimary">
                {text}
            </Typography>
        </Box>
    );
};

export default MobileInformationRow;