import React, { useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

import NavBar from './NavBar';
import TopBar from './TopBar';

interface DashboardLayoutProps {
    children?: ReactNode
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: 'flex',
        height: '100%',
        overflow: 'hidden',
        width: '100%'
    },
    wrapper: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    },
    contentContainer: {
        display: 'flex',
        flex: '1 1 auto',
        overflow: 'hidden'
    },
    content: {
        flex: '1 1 auto',
        height: '100%',
        overflow: 'auto'
    }
}));

const IframeLayout: FC<DashboardLayoutProps> = ({ children }) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);

    if (localStorage.getItem('showNavBar') && localStorage.getItem('showNavBar') === "false") {
        return null;
    }
    else {
        return (
            <div className={classes.root}>
                {<TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />}
                <NavBar
                    onMobileClose={() => setMobileNavOpen(false)}
                    openMobile={isMobileNavOpen}
                />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}


IframeLayout.propTypes = {
    children: PropTypes.node
};

export default IframeLayout;
