import React from 'react';
import type { FC } from 'react';
import { Box } from '@mui/material';
import { ChannelView } from 'src/types/channelView';
import MobileTabContent from './MobileTabContent';
import { Channel } from 'src/types/channel';

interface MobileContentProps {
    channelView: ChannelView
    query: string
    channel: Channel
    setContentLoaded: any
}

const MobileContent: FC<MobileContentProps> = ({ channelView, query, channel, setContentLoaded }) => {
    return (
        <Box display="flex" flexDirection="column" gap={2}>
            {channelView.channelViewTabList.map((tab, index) => {
                if (tab.sequence != 900 && tab.sequence != 901 && (tab.isVisibleInPublicChannelView!== false)) {
                    return (
                        <MobileTabContent setContentLoaded={setContentLoaded} channelView={channelView} channel={channel} key={index} tab={tab} player_uiConfid={channelView.player_uiConfid} query={query}></MobileTabContent>
                    )
                } else return null
            })}
        </Box>
    );
};

export default MobileContent;