import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { SvgIcon, Box, Typography, IconButton, Card, Chip, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
    File as FileIcon,
} from 'react-feather';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import { FileResource } from 'src/types/file_resource';

interface MobileDrawerFileCardProps {
    file: FileResource;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        title: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            height: '2.5rem',
            wordBreak: 'break-word'
        },
        videoIcon: {
            color: theme.palette.text.secondary
        },
        iconButton: {
            padding: 0,
        },
        boxFileIcon: {
            position: 'relative',
            display: "flex",
            backgroundColor: theme.palette.neutral[400],
            aspectRatio: '16 / 9',
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0.25rem",
            cursor: 'pointer',
            width: '100%',
            minWidth: '9.25rem',
            height: 'auto',
        },
        fileIcon: {
            width: '5rem',
            height: '5rem',
            color: theme.palette.primary.contrastText,
        },
        chip: {
            position: 'absolute',
            top: theme.spacing(1),
            right: theme.spacing(1),
            color: 'white',
            cursor: 'pointer',
        },
        image: {
            width: '100%',
            height: 'auto',
            aspectRatio: '16 / 9',
            cursor: 'pointer',
            backgroundColor: theme.palette.background.dark,
        },
    }),
);

const MobileDrawerFileCard: FC<MobileDrawerFileCardProps> = ({ file }) => {
    const classes = useStyles();
    const boxRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);

    const config = getWSConfig();

    const getFileLink = (): void => {
        let params = {}
        params["file_id"] = file.file_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/file_resource/1.0/get/file/link/public', params, config)
            .then(function (response) {
                if (response && response.data) {
                    window.open(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    };

    const openFileLink = (): void => {
        getFileLink();
    };

    const [thumbnailFileLink, setThumbnailFileLink] = useState(null);
    const getThumbnailFileLink = (): void => {
        let params = {}
        params["file_id"] = file.file_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/file_resource/1.0/public/get/thumbnail_link', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setThumbnailFileLink(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
                return null;
            });
    };
    useEffect(() => {
        if (file.thumbnail_extension) getThumbnailFileLink();
    }, []);

    return (
        <Box position="relative">
            <Card sx={{ display: 'flex' }} >
                <Box ref={boxRef} flex="50%" padding={2}>
                    <Box display="flex" gap={1}>
                        <Box>
                            <SvgIcon
                                fontSize="small"
                            >
                                <FileIcon className={classes.videoIcon} />
                            </SvgIcon>
                        </Box>
                        <Box>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                {"File"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box position="relative">
                        <Box
                            onClick={() => openFileLink()}
                            height={90}
                            className={classes.boxFileIcon}>
                            {(file.thumbnail_extension) && <CardMedia className={classes.image}
                                image={thumbnailFileLink}
                            >
                            </CardMedia>}
                            {(!file.thumbnail_extension) && (file.thumbnail_url) && <CardMedia className={classes.image}
                                image={file.thumbnail_url}
                            >
                            </CardMedia>}
                            {(!file.thumbnail_extension) && (!file.thumbnail_url) && <Box className={classes.boxFileIcon}> 
                                <FileIcon className={classes.fileIcon} />
                                <Chip
                                    label={file.extension}
                                    color='primary'
                                    className={classes.chip}
                                    size='small'
                                />
                            </Box>}
                        </Box>
                        <Chip
                            label={file.extension}
                            color='primary'
                            className={classes.chip}
                            size='small'
                        />
                    </Box>
                </Box>
                <Box flex="50%" padding={2} display="flex" flexDirection="column" gap={1} mt={1}>
                    <Box >
                        <Typography
                            ref={titleRef}
                            component="div"
                            variant="h6"
                            className={classes.title}
                        >
                            {file.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <IconButton aria-label="play/pause">
                        </IconButton>
                    </Box>
                </Box>
            </Card >
        </Box >

    );
}

export default MobileDrawerFileCard;
