import React, { useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Card, CardActionArea, CardMedia, Divider, SvgIcon, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {
    Box as BoxIcon,
    Lock as LockIcon,
} from 'react-feather';

import { Model } from 'src/types/model';
import { Channel } from 'src/types/channel';
import ModelBlockModalSuscribe from './ModelBlockModalSuscribe';
import { FormattedMessage } from 'react-intl';

interface PublicModelBoxProps {
    className?: string;
    singleModel?: Model;
    channel: Channel;
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    imageWithAccess: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
        cursor: 'pointer',
    },
    imageWithoutAccess: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
    },
    boxIcon: {
        position: 'relative',
        color: theme.palette.text.secondary,
        float: 'left',
    },
    modelText: {
        overflow: 'hidden',
    },
    lockIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "40%",
        left: "50%",
        transform: "Translate(-50%,-40%)",
        width: '2rem',
        color: 'white',
    },
    buttonIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "70%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        height: '1.5rem',
        width: '2rem',
        color: 'white',
    },
    seeModuleIcon: {
        Size: 14,
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "50%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        height: '1.75rem',
        width: '8rem',
        color: 'white',
    },
    divImage: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        '&:hover': {
            '& svg': {
                visibility: 'visible',
            },
            '& button': {
                visibility: 'visible',
            }
        },
    },
    seeModule: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        '&:hover': {
            '& button': {
                visibility: 'visible',
            }
        },
    },
    actionArea: {
        color: 'TextPrimary',
        '&:hover $focusHighlight': {
            opacity: 0.5,
            color: 'TextPrimary',
        },
    },
    focusHighlight: {},

    titleModel: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '0.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },

    description: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2rem',
    },

    labelModelText: {
        marginLeft: '0.5rem',
        background: 'grey-300',
    },

    boxModelLabelAndFavorite: {
        display: "flex",
    },

}));

const PublicModelBox: FC<PublicModelBoxProps> = ({ className, singleModel, channel }) => {
    const [isOpenlSuscribeDialog, setOpenSuscribeDialog] = useState<boolean>(false);
    const classes = useStyles();

    const handleOpenSuscribeDialog = (): void => {
        setOpenSuscribeDialog(true);
    };

    return (
        <Card
            className={classes.root}
            raised={false}
        >
            <Box
                p={2}
                paddingBottom={3}
            >
                <div className={classes.divImage}>
                    <CardActionArea
                        classes={{
                            root: classes.actionArea,
                            focusHighlight: classes.focusHighlight
                        }}
                        onClick={() => handleOpenSuscribeDialog()}
                    >
                        <CardMedia
                            className={classes.imageWithoutAccess}
                            image={singleModel.thumbnail_url}
                        >
                        </CardMedia>
                    </CardActionArea>
                    <SvgIcon className={classes.lockIcon}
                        fontSize="large"
                        color="action"
                    >
                        <LockIcon />
                    </SvgIcon>
                    <Button className={classes.buttonIcon}
                        variant='outlined'
                        color='inherit'
                        size='small'
                    >
                        <FormattedMessage id="Channel_PublicChannelDetailView_PublicModelBox.Access" />
                    </Button>
                </div>
                <Box
                    className={classes.boxModelLabelAndFavorite}
                >
                    <Box p={1.5} pl={0}>
                        <SvgIcon
                            fontSize="small"
                            className={classes.boxIcon}
                        >
                            <BoxIcon />
                        </SvgIcon>
                    </Box>
                    <Box pt={1.5}>
                        <Typography
                            color="textSecondary"
                            variant="h6"
                            className={classes.modelText}
                        >
                            <FormattedMessage id="Channel_ChannelDetailView_ModelBox.3d_model" />
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography
                        variant="h6"
                        color="textPrimary"
                        className={classes.titleModel}
                    >
                        {singleModel.title}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        className={classes.description}
                    >
                    </Typography>
                </Box>
            </Box>

            {isOpenlSuscribeDialog &&
                <ModelBlockModalSuscribe channel={channel} isOpen={isOpenlSuscribeDialog} setOpen={setOpenSuscribeDialog} />
            }
        </Card>
    );
};

PublicModelBox.propTypes = {
    className: PropTypes.string,
};
export default PublicModelBox;
