import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, IconButton, SvgIcon, Typography, Drawer, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ChevronLeft as ArrowLeftIcon,
} from 'react-feather';
import MobileSearchBar from './MobileSearchBar';
import MobileDrawerVideoCard from './MobileDrawerVideoCard';
import { ChannelView, ChannelViewSection, ChannelViewSubsection, ChannelViewTab } from 'src/types/channelView';
import { Media } from 'src/types/media';
import { useIntl } from 'react-intl';
import { FileResource } from 'src/types/file_resource';
import { ExternalLinkResource } from 'src/types/external_link_resource';
import { Model } from 'src/types/model';
import { Channel } from 'src/types/channel';
import MobileDrawerModelCard from './MobileDrawerModelCard';
import MobileDrawerFileCard from './MobileDrawerFileCard';
import MobileDrawerExternalLinkCard from './MobileDrawerExternalLinkCard';

interface MobileSubsectionDrawerProps {
    isOpen?: boolean;
    onClose?: () => void;
    subsection?: ChannelViewSubsection
    tab?: ChannelViewTab
    section?: ChannelViewSection
    media: Media[];
    models: Model[];
    files: FileResource[];
    externalLinks: ExternalLinkResource[];
    channelView: ChannelView;
    channel: Channel;
    beforeName: string;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    drawer: {
        width: '100%',
        zIndex: theme.zIndex.drawer,
        backgroundColor: theme.palette.background.dark,
        paddingBottom: '3rem'
    },
    arrowLeft: {
        paddingLeft: 0
    },
}));

const MobileSubsectionDrawer: FC<MobileSubsectionDrawerProps> = ({
    isOpen,
    onClose,
    subsection,
    media,
    models,
    files,
    externalLinks,
    channelView,
    beforeName,
    tab,
    channel,
    section
}) => {
    const classes = useStyles();
    const [query, setQuery] = useState<string>('');
    const intl = useIntl();

    const closeDrawer = (): void => {
        onClose()
    }


    const applyFiltersForTitle = (item: Media | Model | FileResource | ExternalLinkResource, query: string): Boolean => {
        let matches = true;
        if (query) {
            const properties = ['title'];
            let containsQuery = false;
            let query_norm;
            let model_norm;
            query_norm = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            properties.forEach((property) => {
                if (item[property] !== undefined) {
                    model_norm = item[property].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    if (model_norm.toLowerCase().includes(query_norm.toLowerCase())) {
                        containsQuery = true;
                    }
                }
            });
            if (!containsQuery) {
                matches = false;
            }
        }
        return matches;
    };
    const applyMediasFilters = (medias: Media[], query: string): Media[] => {
        return medias.filter((media) => {
            let matches = true;
            if (query) {
                const properties = ['title'];
                let containsQuery = false;
                let query_norm;
                let media_norm;
                query_norm = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                properties.forEach((property) => {
                    if (media[property] !== undefined) {
                        media_norm = media[property].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                        if (media_norm.toLowerCase().includes(query_norm.toLowerCase())) {
                            containsQuery = true;
                        }
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
            }
            return matches;
        });
    };

    const applyModelsFilters = (models: Model[], query: string): Model[] => {
        return models.filter((model) => {
            let matches = applyFiltersForTitle(model, query);
            return matches;
        });
    };
    const applyFilesFilters = (files: FileResource[], query: string): FileResource[] => {
        return files.filter((file) => {
            let matches = applyFiltersForTitle(file, query);
            return matches;
        });
    };
    const applyExternalLinksFilters = (externalLinks: ExternalLinkResource[], query: string): ExternalLinkResource[] => {
        return externalLinks.filter((externalLink) => {
            let matches = applyFiltersForTitle(externalLink, query);
            return matches;
        });
    };

    const filteredMedias = (media === undefined) ? [] : applyMediasFilters(media, query);
    const filteredModels = (models === null) ? [] : applyModelsFilters(models, query);
    const filteredFiles = (files === null) ? [] : applyFilesFilters(files, query);
    const filteredExternalLinks = (externalLinks === null) ? [] : applyExternalLinksFilters(externalLinks, query);

    const getName = (): string => {
        if (tab) return tab.name
        else if (section) return section.name
        else return subsection.name
    }

    return (
        <Drawer
            classes={{ paper: classes.drawer }}
            anchor="left"
            open={isOpen}>
            <Container>
                <Box mt={2} display="flex" alignItems="center" flexWrap="wrap">
                    <IconButton className={classes.arrowLeft} onClick={closeDrawer}>
                        <SvgIcon >
                            <ArrowLeftIcon />
                        </SvgIcon>
                    </IconButton>
                    <Typography
                        variant="body2"
                        color='textSecondary'>
                        {beforeName}
                    </Typography>
                </Box>
                <Box mt={3} display="flex" flexDirection="column" justifyContent="space-between" gap={3}>
                    <Typography
                        variant="h3"
                        color='textPrimary'>
                        {getName()}
                    </Typography>
                    <MobileSearchBar setQuery={setQuery}>
                    </MobileSearchBar>
                    <Typography
                        variant="h5"
                        color='textPrimary'>
                        {intl.formatMessage({ id: 'channel.content' })}
                    </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap={2} mt={2}>
                    {filteredMedias.map((video, index) => {
                        return (
                            <Box key={index}>
                                <MobileDrawerVideoCard channelView={channelView} video={video}></MobileDrawerVideoCard>
                            </Box>
                        )
                    })}
                    {/* Models rendering */}
                    {filteredModels.map((model, index) => {
                        return (
                            <Box key={index}>
                                <MobileDrawerModelCard key={index} singleModel={model} channel={channel} />
                            </Box>
                        );
                    })}

                    {/* Files rendering */}
                    {filteredFiles.map((file, index) => {
                        return (
                            <Box key={index}>
                                <MobileDrawerFileCard key={index} file={file} />
                            </Box>

                        );
                    })}

                    {/* Links rendering */}
                    {filteredExternalLinks.map((link, index) => {
                        return (
                            <Box key={index}>

                                <MobileDrawerExternalLinkCard key={index} external_link={link} />
                            </Box>

                        );
                    })}

                </Box>
            </Container>
        </Drawer>
    );
};

export default MobileSubsectionDrawer;