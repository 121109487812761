import { useRef, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import {
    Box,
    Card,
    Input,
    Typography,
    CardActions,
    MenuItem,
    ListItemIcon,
    Divider,
    Menu,
    Button,
    TextField,
    Chip,
    ListItemText,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Search as SearchIcon,
    Grid as AllContentIcon,
    Box as ModelIcon,
    Video as VideoIcon,
    Filter as FilterIcon,
    File as FileIcon,
    ExternalLink as LinkIcon
} from 'react-feather';

import { ChannelViewTab } from 'src/types/channelView';
import { Channel } from 'src/types/channel';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Autocomplete } from '@mui/lab';
import { FormattedMessage, useIntl } from 'react-intl';

interface SearchBarMenuProps {
    setVideoChecked: any;
    setModelsChecked: any;
    setFilesChecked: any;
    setExternalLinksChecked: any;
    setOpenActionsMenu: any;
    actionsRef: any;
    openActionsMenu: boolean;
    setSelectedMenuItem: any;
}
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        minHeight: 64,
    },
    queryField: {
        width: '100%',
        marginLeft: theme.spacing(2)
    },
    checkboxVideos: {
        color: theme.palette.primary.main + "!important",
    },
    checkboxModels: {
        color: theme.palette.primary.main + "!important",
    },
    VideoText: {
        marginRight: '2rem',
    },
    ModelText: {
        marginRight: '2rem',
    },
    ShowMe: {
        marginRight: '2rem',
        marginLeft: '0rem',
    },
    BoxCheckBox: {
        marginRight: '2rem',
    },
    buttonMenu: {
        color: theme.palette.text.primary,
    },
    buttonMenuRight: {
        backgroundColor: "white",
        minWidth: "8rem",
        color: theme.palette.text.primary
    },
    IconOnButton: {
        marginRight: '1rem'
    },
    MenuStyle: {
        transform: "Translate(-0.5%,4.5%)",
        paddingBottom: 0,
        '& .MuiList-padding': {
            minWidth: "20.5rem",
        },
    },
    cardFilter: {
        paddingTop: "1rem",
        paddingLeft: "1rem",
        paddingBottom: "1.5rem",
        paddingRight: "1rem",
    },
    menuFilter: {
        transform: "Translate(-1.5%,6.5%)",
        '& .MuiList-padding': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },
    MenuItemStyle: {
        paddingBottom: "1rem",
        paddingTop: "1rem",
    },
    listItemIcon: {
        minWidth: "0rem",
        alignItems: "center",
        paddingRight: "1rem"
    },
    icon: {
        transform: 'translateY(10%)'
    },
}));

const SearchBarMenu: FC<SearchBarMenuProps> = ({
    setModelsChecked,
    setVideoChecked,
    setFilesChecked,
    setExternalLinksChecked,
    setOpenActionsMenu,
    setSelectedMenuItem,
    actionsRef,
    openActionsMenu
}) => {
    const classes = useStyles();
    const intl = useIntl();

    const handleCloseActionsMenu = (): void => {
        setOpenActionsMenu(false);
    };

    const handleViewAllContent = (): void => {
        setSelectedMenuItem(0)
        handleCloseActionsMenu();
        setVideoChecked(true);
        setModelsChecked(true);
        setFilesChecked(true);
        setExternalLinksChecked(true);
    };

    const handleViewVideos = (): void => {
        setSelectedMenuItem(1)
        handleCloseActionsMenu();
        setVideoChecked(true);
        setModelsChecked(false);
        setFilesChecked(false);
        setExternalLinksChecked(false)
    };

    const handleViewModels = (): void => {
        setSelectedMenuItem(2)
        handleCloseActionsMenu();
        setVideoChecked(false);
        setModelsChecked(true);
        setFilesChecked(false);
        setExternalLinksChecked(false)
    };

    const handleViewFiles = (): void => {
        setSelectedMenuItem(3)
        handleCloseActionsMenu();
        setVideoChecked(false);
        setModelsChecked(false);
        setFilesChecked(true);
        setExternalLinksChecked(false)
    };
    const handleViewExternalLinks = (): void => {
        setSelectedMenuItem(4)
        handleCloseActionsMenu();
        setVideoChecked(false);
        setModelsChecked(false);
        setFilesChecked(false);
        setExternalLinksChecked(true)
    };

    return (
        <div>
            <Menu
                className={classes.MenuStyle}
                anchorEl={actionsRef.current}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                }}
                open={openActionsMenu}
                onClose={handleCloseActionsMenu}
            >
                <MenuItem
                    onClick={handleViewAllContent}
                    className={classes.MenuItemStyle}
                >
                    <ListItemIcon>
                        <AllContentIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"><FormattedMessage id="Channel_ChannelDetailView_SearchBar_Menu.allcontent" /></Typography>
                </MenuItem>
                <Divider></Divider>
                <MenuItem
                    onClick={handleViewVideos}
                    className={classes.MenuItemStyle}
                >
                    <ListItemIcon>
                        <VideoIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit"><FormattedMessage id="Channel_ChannelDetailView_SearchBar_Menu.videos" /></Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleViewModels}
                    className={classes.MenuItemStyle}
                >
                    <ListItemIcon>
                        <ModelIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        <FormattedMessage id="Channel_ChannelDetailView_SearchBar_Menu.models3d" />
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleViewFiles}
                    className={classes.MenuItemStyle}
                >
                    <ListItemIcon>
                        <FileIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        <FormattedMessage id="Channel_ChannelDetailView_SearchBar_Menu.files" />
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleViewExternalLinks}
                    className={classes.MenuItemStyle}
                >
                    <ListItemIcon>
                        <LinkIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                        <FormattedMessage id="Channel_ChannelDetailView_SearchBar_Menu.external_links" />
                    </Typography>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default SearchBarMenu;