import React, { useState } from 'react';
import type { FC } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    SvgIcon,
    TextField,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    X as XIcon,
} from 'react-feather';
import { getWSConfig } from 'src/views/utils/Utils';
import { FormattedMessage } from 'react-intl';
import { Media } from 'src/types/media';
import axios from 'axios';

interface VideoPasswordDialogProps {
    isOpen: boolean;
    setOpen: any;
    media: Media;
    channel_id: number;
    handleOpenVideoIfCorrectPassword: (media: Media) => void;
}

const VideoPasswordDialog: FC<VideoPasswordDialogProps> = ({ isOpen, setOpen, media, channel_id, handleOpenVideoIfCorrectPassword }) => {
    const useStyles = makeStyles(() => ({
        root: {},
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cancelButton: {
            marginRight: "1rem",
        }
    }));
    const config = getWSConfig();
    const classes = useStyles();
    const [password, setPassword] = useState<string>("");
    const [incorrectPassword, setIncorrectPassword] = useState<boolean>(false);

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        setIncorrectPassword(false);
    };

    const checkPasswordWS = (): void => {
        let params = { "channel_id": channel_id, "kaltura_video_id": media.entry_id, "password": password }
        axios.post(process.env.REACT_APP_SERVER_URL + '/channel_video/1.0/check/password', params, config)
            .then(function (response) {
                if (response && response.data != null) {
                    if (Boolean(response.data)) {
                        handleClose();
                        handleOpenVideoIfCorrectPassword(media)
                    } else {
                        setIncorrectPassword(true);
                    }
                }
            })
    }

    return (
        <Dialog
            maxWidth="sm"
            className={classes.container}
            onClose={handleClose}
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="h4"
                        color="textPrimary"
                    >
                        <FormattedMessage id="channel.PublicChannelDetailView.VideoPasswordDialog.password_title" />
                    </Typography>

                    <IconButton onClick={handleClose} size="large">
                        <SvgIcon fontSize="small">
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box pt={1}>
                    <TextField
                        error={incorrectPassword}
                        type={'text'}
                        value={password}
                        label={"Password"}
                        onChange={handleChangePassword}
                        helperText={incorrectPassword ? "Incorrect password" : ""}
                    >
                        {password}
                    </TextField>
                </Box>
                <Box
                    mt={3}
                    mb={2}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="right"
                >
                    <Button
                        color="primary"
                        size="large"
                        variant="outlined"
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        <FormattedMessage id="channel.PublicChannelDetailView.VideoPasswordDialog.cancel" />
                    </Button>
                    <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={() => checkPasswordWS()}
                    >
                        <FormattedMessage id="channel.PublicChannelDetailView.VideoPasswordDialog.accept" />
                    </Button>

                </Box>
            </DialogContent>
        </Dialog >
    );
};

export default VideoPasswordDialog;
