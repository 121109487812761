import axios from "axios";
import React, { createContext, ReactChild, useCallback, useContext, useEffect, useRef, useState } from "react"
import { WebRTCUser } from "src/types/WebRTC";
import { getWSConfig } from "src/views/utils/Utils";

const pc_config = {
    sdpSemantics: 'unified-plan',
    iceServers: [{ urls: ['stun:stun.l.google.com:19302'] }]
}

let websocketUrl;
if (location.protocol === "https:") {
    websocketUrl = "wss://vr.server.digimevo.com/" 
} else {
    websocketUrl = "ws://vrremote-env.eba-taibipem.eu-west-1.elasticbeanstalk.com/"
}

export const WebSocketContext = React.createContext({
    ws: null,
    users: [],
    selectedUser: null,
    controlPanelState: null,
    selectUser: (user) => {},
    isUserSelected: false,
    createReceivePC: (id: string) => {},
    passDataToServer: (data) => {}
});

export interface ControlPanelStateProps {
    type: string;
    params?: any;
}

export interface PCData {
    pc: RTCPeerConnection;
    channel: RTCDataChannel;
}

export const WebSocketProvider = ({children}) => {
    let ws: WebSocket;
    
    let socketId; 

    const config = getWSConfig();

    const sendPCRef = useRef<PCData>({pc: null, channel: null});
    const receivePCsRef = useRef<{ [socketId: string]: PCData }>({});
    const senders = useRef<WebRTCUser[]>([]);
    const [users, setUsers] = useState<Array<WebRTCUser>>([]);
    const selectedUser = useRef<WebRTCUser>(null);
    const [isUserSelected, setIsUserSelected] = useState<boolean>(false);
    // const [selectedUser, setSelectedUser] = useState<WebRTCUser>(null);
    // const [localStream, setLocalStream] = useState<MediaStream>(new MediaStream());

    const [controlPanelState, setControlPanelState] = useState<ControlPanelStateProps>();

    const closeReceivePC = useCallback((id: string) => {
        if (!receivePCsRef.current[id]) return;
        if (id === selectedUser.current?.id) selectUser(null);
        receivePCsRef.current[id].pc.close();
        delete receivePCsRef.current[id];
    }, []);

    const createReceiverOffer = useCallback(
        async (pc: RTCPeerConnection, senderSocketID: string) => {
            try {
                const sdp = await pc.createOffer({
                offerToReceiveAudio: false,
                offerToReceiveVideo: false,
                });
                // console.log("create receiver offer success");
                await pc.setLocalDescription(new RTCSessionDescription(sdp));
        
                if (!ws) return;
                sendMessage({
                    type: 'receiverOffer',
                    data: {
                        sdp,
                        receiverSocketID: socketId,
                        senderSocketID
                    }
                });
            } catch (error) {
                console.log(error);
            }
        },
        []
    );

    const createReceiverPeerConnection = useCallback((socketID: string) => {
        try {
            const pc = new RTCPeerConnection(pc_config);
    
            receivePCsRef.current[socketID] = {
                pc: pc,
                channel: null
            }
        
            console.log('receivePCsRef', receivePCsRef.current);

            receivePCsRef.current[socketID].pc.onicecandidate = (e) => {
                if (!(e.candidate && ws)) return;
                sendMessage({
                    type: "receiverCandidate", 
                    data: {
                        candidate: e.candidate,
                        receiverSocketID: socketId,
                        senderSocketID: socketID,
                    }
                });
            };
        
            receivePCsRef.current[socketID].pc.oniceconnectionstatechange = (e) => {
                console.log('ICE state: ', e);
            };

            receivePCsRef.current[socketID].pc.ontrack = (e) => {
                console.log("ontrack success", e);
                if (e.track.kind === 'video' ||e.track.kind === 'audio') {
                    // let newUsers = [...senders.current];
                    // let userIndex = newUsers.findIndex(user => user.id === socketID);
                    // newUsers[userIndex].stream = e.streams[0];
                    // setUsers(newUsers);

                    // setLocalStream(e.streams[0]);
                    // setLocalStream(newUsers[userIndex].stream);
                }
            };

            receivePCsRef.current[socketID].channel = receivePCsRef.current[socketID].pc.createDataChannel('data');
            console.log('channel created', receivePCsRef.current[socketID].channel);

            receivePCsRef.current[socketID].channel.onopen = () => {
                console.log('datachannel connected', receivePCsRef.current[socketID].channel);
            }
            receivePCsRef.current[socketID].channel.onerror = function (e) {
                console.log("The error " + e + " occurred\n while handling data with proxy server.");
            };
            receivePCsRef.current[socketID].channel.onclose = function () {
                console.log('Datachannel disconnected.');
            };
            receivePCsRef.current[socketID].channel.onmessage = async (msg) => {
                // receive message from unity and operate message
                let data;
                // receive message data type is blob only on Firefox
                if (navigator.userAgent.indexOf('Firefox') != -1) {
                    data = await msg.data.arrayBuffer();
                } else {
                    data = msg.data;
                }
                if (isJson(data)) {
                    data = JSON.parse(data);
                }
                console.log('receiver onmessage', data);
            };
            return receivePCsRef.current[socketID].pc;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }, []);

    const createReceivePC = useCallback(
        (id: string) => {
            try {
                console.log(`socketID(${id}) user entered`);
                const pc = createReceiverPeerConnection(id);
                if (!(ws && pc)) return;
                createReceiverOffer(pc, id);
            } catch (error) {
                console.log(error);
            }
        },
        [createReceiverOffer, createReceiverPeerConnection]
    );

    const createSenderOffer = useCallback(async (socketID) => {
        try {
            if (!sendPCRef.current) return;
            const sdp = await sendPCRef.current.pc.createOffer({
                offerToReceiveAudio: false,
                offerToReceiveVideo: false,
            });
            // console.log("create sender offer success");
            await sendPCRef.current.pc.setLocalDescription(
                new RTCSessionDescription(sdp)
            );
        
            if (!ws) return;
            sendMessage({
                type: "senderOffer", 
                data: {
                    sdp,
                    senderSocketID: socketID,
                }
            });
        } catch (error) {
            console.log(error);
        }
    }, []);
    
    const createSenderPeerConnection = useCallback((socketID) => {
        const pc = new RTCPeerConnection(pc_config);
    
        sendPCRef.current.pc = pc;
        sendPCRef.current.pc.onicecandidate = (e) => {
            // console.log('on sender candidate', e);
            if (!(e.candidate && ws)) return;
            // console.log("sender PC onicecandidate");
            sendMessage({
                type: "senderCandidate", 
                data: {
                    candidate: e.candidate,
                    senderSocketID: socketID,
                }
            });
        };
    
        sendPCRef.current.pc.oniceconnectionstatechange = (e) => {
            // console.log(e);
        };

        sendPCRef.current.channel = sendPCRef.current.pc.createDataChannel('data');
        console.log('channel created', sendPCRef.current.channel);

        sendPCRef.current.channel.onopen = () => {
            console.log('datachannel connected', sendPCRef.current.channel);
        }
        sendPCRef.current.channel.onerror = function (e) {
            console.log("The error " + e + " occurred\n while handling data with proxy server.");
        };
        sendPCRef.current.channel.onclose = function () {
            console.log('Datachannel disconnected.');
        };
        sendPCRef.current.channel.onmessage = async (msg) => {
            // receive message from unity and operate message
            let data;
            // receive message data type is blob only on Firefox
            if (navigator.userAgent.indexOf('Firefox') != -1) {
                data = await msg.data.arrayBuffer();
            } else {
                data = msg.data;
            }
            if (isJson(data)) {
                data = JSON.parse(data);
            }

            if (data.type === "sessionId") {
                updateInfoUser(data.from, data.id);
            }

            if (selectedUser.current?.id === data?.from) {
                setControlPanelState(data);
            }
        };
    
    }, []);

    const initPC = useCallback(async (socketID) => {
        createSenderPeerConnection(socketID);
        await createSenderOffer(socketID);
        sendMessage({
            type: "getAllSenders", 
            data: {
                id: socketID,
            }
        });
    }, []);

    useEffect(() => {
        ws = new WebSocket(websocketUrl);

        ws.onopen = (e) => {
            console.log('ws connected');
            sendMessage({
                type: 'askConnectionId',
                from: 'browser'
            })
        }

        ws.onerror = (e) => {
            console.log(e);
        }

        ws.onmessage = (e) => handleOnMessage(e);
        
        ws.onclose = (e) => {
            console.log(e);
            console.log('ws closed');
        }

        return () => {
            console.log("entering in cleanup return")
            if (socketIsReady()) {
                console.log("ws closed in cleanup")
                ws.close();
            }
            users.forEach(user => closeReceivePC(user.id));
        }
    }, [])


    useEffect(() => {
        let foundSelected = false;
        for (let user of users) {
            if (user.id === selectedUser.current?.id) {
                foundSelected = true;
                break;
            }
        }
        if (!foundSelected) selectUser(null);
    }, [users])

    const handleOnMessage = async (e) => {
        const body = JSON.parse(e.data);
        // console.log('ws onmessage', body);

        switch (body.type) {
            case 'connect':
                socketId = body.connectionId;
                initPC(body.connectionId);
                break;
            case 'allSenders': 
                console.log('allSenders', body.data)
                let _senders = [];
                for (let senderSocketID of body.data.users) {
                    createReceivePC(senderSocketID);
                    _senders.push({
                        id: senderSocketID,
                        // stream: new MediaStream()
                    })
                }
                setUsers(_senders);
                break;
            case 'userExit':
                closeReceivePC(body.data.id);
                setUsers((users) => users.filter((user) => user.id !== body.data.id));
                break;
            case 'getSenderAnswer':
                try {
                    if (!sendPCRef.current) return;
                    await sendPCRef.current.pc.setRemoteDescription(
                      new RTCSessionDescription(body.data)
                    );
                } catch (error) {
                    console.log(error);
                }
                break;
            case 'getSenderCandidate':
                try {
                    if (!(body.data.candidate && sendPCRef.current)) return;
                    await sendPCRef.current.pc.addIceCandidate(
                      new RTCIceCandidate(body.data.candidate)
                    );
                } catch (error) {
                    console.log(error);
                }
                break;
            case 'getReceiverAnswer':
                try {
                    const pc: RTCPeerConnection = receivePCsRef.current[body.data.id].pc;
                    if (!pc) return;
                    await pc.setRemoteDescription(body.data.sdp);
                    // console.log(`socketID(${body.data.id})'s set remote sdp success`);
                } catch (error) {
                    console.error(error);
                }
                break;
            case 'getReceiverCandidate':
                try {
                    const pc: RTCPeerConnection = receivePCsRef.current[body.data.id].pc;
                    if (!(pc && body.data.candidate)) return;
                    await pc.addIceCandidate(new RTCIceCandidate(body.data.candidate));
                    // console.log(`socketID(${body.data.id})'s candidate add success`);
                } catch (error) {
                    console.error(error);
                }
                break;
            default:
                console.log('on message new case', body.type);
        }
    }

    const sendMessage = (object) => {
        if (socketIsReady()) {
            // console.log('send', object)
            ws.send(JSON.stringify(object))
        } else {
            console.log('socket not opened yet')
        }
    }

    const socketIsReady = () => {
        return ws.readyState === WebSocket.OPEN;
    }

    useEffect(() => {
        senders.current = users;
    }, [users])
    
    const selectUser = (user: WebRTCUser) => {
        selectedUser.current = user;
        setIsUserSelected(user ? true: false);
    }

    const isJson = (str: string) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    const passDataToServer = (msg) => {
        let _msg = {
            ...msg,
            type: msg.type,
            data: {
                ...msg.data,
                senderSocketID: selectedUser.current?.id
            }
        }
        console.log('passDataToServer', _msg);
        if (sendPCRef.current.channel && sendPCRef.current.channel.readyState == 'open') sendPCRef.current.channel.send(JSON.stringify(_msg));
        else {
            console.log('channel ready state is', sendPCRef.current?.channel?.readyState);
        }
    }

    const updateInfoUser = (id, sessionId) => {
        axios.post(process.env.REACT_APP_SERVER_URL + '/vr_session/1.0/get/vr_program/by/vr_session/id', {vr_session_id: sessionId}, config)
        .then(response => {
            if (response.data) {
                let _users = [...senders.current];
                let i = _users.findIndex(user => user.id === id);
                console.log(senders.current, id, _users[i])
                _users[i].programTitle = response.data.title;
                _users[i].sessionName = response.data.vrSessionList.find(session => session.vr_session_id === sessionId).name;
                _users[i].name = response.data.patientName;
                setUsers(_users);
            }
        })
        .catch(error => 
            console.log("error getting vr program by session id from WS", error)
        )
    }

    return (
        <WebSocketContext.Provider value={{
            ws,
            users,
            selectedUser: selectedUser.current,
            controlPanelState,
            selectUser,
            isUserSelected,
            createReceivePC,
            passDataToServer,
        }}>{children}</WebSocketContext.Provider>
    )
}

export default WebSocketContext;