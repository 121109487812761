import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, Card, Divider, Grid, CardMedia, IconButton, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    ExternalLink as ExternalLinkIcon,
    Share2 as ShareIcon,
} from 'react-feather';
import { ExternalLinkResource } from 'src/types/external_link_resource';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';

interface ExternalLinkBoxProps {
    external_link: ExternalLinkResource;
    mode: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    boxIcon: {
        position: 'relative',
        color: theme.palette.text.secondary,
        float: 'left',
    },
    fileText: {
        overflow: 'hidden',
    },
    titleFile: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '0.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    boxFileIcon: {
        position: 'relative',
        display: "flex",
        backgroundColor: theme.palette.neutral[400],
        aspectRatio: '16 / 9',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0.25rem",
        cursor: 'pointer',
        width: '100%',
        height: 'auto',
    },
    fileIcon: {
        width: '5rem',
        height: '5rem',
        color: theme.palette.primary.contrastText,
    },
    description: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2rem',
    },
    image: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.dark,
    },
}));


const ExternalLinkBox: FC<ExternalLinkBoxProps> = ({ external_link, mode }) => {
    const config = getWSConfig();
    const [isOpenlSuscribeDialog, setOpenSuscribeDialog] = useState<boolean>(false);
    const classes = useStyles();
    const [thumbnailUrl, setThumbnailUrl] = useState<string>('');

    const openExternalLink = (): void => {
        window.open(external_link.link);
    };

    const [externalLinkThumbnailLink, setExternalLinkThumbnailLink] = useState(null);
    const getExternalLinkThumbnailLink = (): void => {
        let params = {}
        params["external_link_resource_id"] = external_link.external_link_resource_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/external_link_resource/1.0/get/thumbnail_link', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setExternalLinkThumbnailLink(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
                return null;
            });
    };
    useEffect(() => {
        if (external_link.thumbnail_extension) getExternalLinkThumbnailLink();
    }, []);

    return (
        <Grid
            item
            key={external_link.external_link_resource_id}
            xs={mode === 'grid' ? 12 : 12}
            sm={mode === 'grid' ? 12 : 6}
            md={mode === 'grid' ? 6 : 4}
            lg={mode === 'grid' ? 4 : 3}
            xl={mode === 'grid' ? 3 : 3}
        >
            <Card
                className={classes.root}
                raised={false}
                onClick={() => openExternalLink()}
            >
                <Box
                    p={2}
                    paddingBottom={3}
                >
                    {(external_link.thumbnail_extension) && <CardMedia className={classes.image}
                        image={externalLinkThumbnailLink}
                    >
                    </CardMedia>}
                    {(!external_link.thumbnail_extension) && (external_link.thumbnail_url) && <CardMedia className={classes.image}
                        image={external_link.thumbnail_url}
                    >
                    </CardMedia>}
                    {(!external_link.thumbnail_extension) && (!external_link.thumbnail_url) && <Box className={classes.boxFileIcon}> 
                        <ExternalLinkIcon className={classes.fileIcon} />
                    </Box>}
                    <Box
                        display={"flex"}
                    >
                        <Box p={1.5} pl={0}>
                            <SvgIcon
                                fontSize="small"
                                className={classes.boxIcon}
                            >
                                <ExternalLinkIcon />
                            </SvgIcon>
                        </Box>
                        <Box pt={1.5}>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                                className={classes.fileText}
                            >
                                {"Link"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography
                            variant="h6"
                            color="textPrimary"
                            className={classes.titleFile}
                        >
                            {external_link.title}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            className={classes.description}
                        >
                            {external_link.description}
                        </Typography>
                    </Box>
                </Box>
                <Divider />
            </Card>
        </Grid>
    );

};

export default ExternalLinkBox;
