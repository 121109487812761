import React, { useState, useEffect, useCallback } from 'react';
import type { FC } from 'react';
import { Box, Container, Hidden, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';

import Page from 'src/components/Page';
import { getAndStoreKalturaVideoPlayerIdByChannelId, getWSConfig } from 'src/views/utils/Utils';
import { useParams } from 'react-router-dom';
import type { ChannelView, ChannelViewInfoType } from 'src/types/channelView';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import type { Channel } from 'src/types/channel'
import PublicChannelViewTabs from './PublicChannelViewTabs';
import PublicHeader from './PublicHeader';
import PublicHeaderBar from './PublicHeaderBar';
import Footer from './Footer';
import type { Media } from 'src/types/media';
import MobileView from './mobile/MobileView';
import VideoPlayerDialog from 'src/views/VideoPlayer/VideoPlayerDialog';

interface PublicChannelDetailViewProps {
    subdomainchannel?: number,
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100vh',
        paddingBottom: theme.spacing(3)
    },
    background: {
        backgroundColor: theme.palette.background.dark,
    }
}));

const PublicChannelDetailView: FC<PublicChannelDetailViewProps> = ({
    subdomainchannel,
}) => {
    const classes = useStyles();
    const [channelView, setChannelView] = useState<ChannelView>()
    const [channel, setChannel] = useState<Channel>()
    const [loading, setLoading] = useState(true);
    const config = getWSConfig();
    const paramToken = useParams();
    const [isVideoPlayDialogOpen, setVideoPlayDialogOpen] = useState<boolean>(false);
    const [mediaToModal, setMediaToModal] = useState<Media>();
    const [playerTime, setPlayerTime] = useState<number | null>(null);

    const theme = useTheme();
    const mobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

    const getChannel = (): void => {
        let params = { "channel_id": channelView.channel_id }
        axios.post(process.env.REACT_APP_SERVER_URL + '/channel/1.0/public/get/channel', params, config)
            .then(function (response) {
                if (response && response.data) {
                    let channelTemp: Channel = response.data
                    setChannel(response.data)
                    let channelViewTemp = channelView
                    channelViewTemp.channel_name = channelTemp.name
                    setLoading(false);
                }
            })
    }

    const getChannelView = (): void => {
        let params = {};
        if (paramToken['channelView'] !== undefined && paramToken['channelView'] !== "") {
            params['channel_view_id'] = parseInt(paramToken['channelView']);
        }
        if (subdomainchannel != null || subdomainchannel != undefined) {
            params['channel_view_id'] = subdomainchannel;
        }
        axios.post(process.env.REACT_APP_SERVER_URL + '/channel_view/1.0/public/get/full', params, config)
            .then(function (response) {
                if (response && response.data) {
                    const channelViewTemp: ChannelView = response.data;
                    setChannelView(channelViewTemp);
                    if (paramToken['video_id'] !== null && paramToken['video_id'] !== undefined) {
                        let params_video = {};
                        params_video['media_id'] = paramToken['video_id'];
                        axios.post(process.env.REACT_APP_SERVER_URL + '/media/1.0/get/media', params_video, config)
                            .then(function (response) {
                                if (response && response.data && paramToken['video_id']) {
                                    setMediaToModal(response.data);
                                    setVideoPlayDialogOpen(true);
                                }
                            })
                            .catch(function (error) {
                                console.log("error getting video_id viewfrom WS");
                                if (error && error.response && error.response.data) {
                                    console.log(error.response.data);
                                }
                            })
                    }
                }
            })
            .catch(function (error) {
                console.log("error getting channel view from WS");
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            })
    }

    const handleCloseVideoPlayDialog = (): void => {
        setVideoPlayDialogOpen(false);
    };

    useEffect(() => {
        getChannelView();
    }, [paramToken['channelView']]);

    useEffect(() => {
        if (channelView && channelView.channel_id) {
            getChannel();
            getAndStoreKalturaVideoPlayerIdByChannelId(channelView.channel_id);
        }
    }, [channelView]);


    if (loading == true) {
        return null;
    }

    else {
        return (
            <div className={classes.background} >
                {paramToken['video_id'] && paramToken['channelView'] && isVideoPlayDialogOpen &&
                    <VideoPlayerDialog
                        isOpen={isVideoPlayDialogOpen}
                        onClose={handleCloseVideoPlayDialog}
                        video={mediaToModal}
                        playerTime={playerTime}
                        setPlayerTime={setPlayerTime}
                        channelViewId={paramToken['channelView']}
                    />
                }
                <Page
                    className={classes.root}
                    title="Canal"
                >
                    <Box display={mobile == false ? 'none' : 'block'}>
                        <MobileView channel={channel} channelView={channelView}></MobileView>
                    </Box>
                    <Box display={mobile == true ? 'none' : 'block'}>
                        <PublicHeaderBar
                            channel={channel}
                            channelViewInfo={channelView.channelViewInfo}
                        />
                        <PublicHeader channel={channel} channelViewInfo={channelView.channelViewInfo}></PublicHeader>
                        <PublicChannelViewTabs
                            channelViewTabList={channelView.channelViewTabList}
                            channel={channel}
                            liveEntryId={channelView.liveEntryId}
                            channelViewInfo={channelView.channelViewInfo}
                        />
                    </Box>
                </Page>
                <Footer
                    channelViewInfo={channelView.channelViewInfo}
                    channel={channel}
                />
            </div>
        );
    }
};

export default PublicChannelDetailView;
