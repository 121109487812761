import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, SvgIcon, Typography, Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    Link as LinkIcon,
    Link2 as Link2Icon
} from 'react-feather';
import { ChannelView } from 'src/types/channelView';
import { useSnackbar } from 'notistack';
import { Media } from 'src/types/media';

interface MobileMoreOptionsDrawerProps {
    channelView: ChannelView;
    video: Media;
    setMoreOptionsOpen: any;
}

const useStyles = makeStyles((theme) => ({
    root: {},
    card: {
        width: '80%',
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, 50%)',
        height: '50%',
        zIndex: theme.zIndex.drawer,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)'
    },
    optionsBox: {
        transform: 'translate(0, -25%)',
        display: 'flex',
        gap: 20,
        color: theme.palette.text.secondary,
    },
}));

const MobileMoreOptionsDrawer: FC<MobileMoreOptionsDrawerProps> = ({
    channelView,
    video,
    setMoreOptionsOpen
}) => {
    const classes = useStyles();

    const { enqueueSnackbar } = useSnackbar();
    const copyURL = (): void => {
        navigator.clipboard.writeText(window.location.origin + "/channelview/" + channelView.channel_view_id + "/" + video.entry_id);
        enqueueSnackbar("URL copiada en el portapapeles con éxito", { variant: 'success' });
        setMoreOptionsOpen(false)
    }

    return (
        <Card className={classes.card} onClick={copyURL}>
            <CardContent>
                <Box className={classes.optionsBox}>
                    <SvgIcon>
                        <Link2Icon></Link2Icon>
                    </SvgIcon>
                    <Typography variant="h4" color="textPrimary">
                        URL
                    </Typography>
                    <SvgIcon sx={{ marginLeft: 'auto' }}>
                        <LinkIcon></LinkIcon>
                    </SvgIcon>
                </Box>
            </CardContent>
        </Card>
    );
};

export default MobileMoreOptionsDrawer;