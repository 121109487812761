import React, { useState, useEffect, useRef } from "react";
import {
    KalturaPlayer,
    usePlayerUpdates,
    usePlayer,
} from "src/kaltura-player";
import type { FC } from 'react';
import PropTypes from 'prop-types';
import type { Media } from 'src/types/media';
import { Button } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Hotspot } from "src/types/hotspot";

export interface EntriesConfig {
    entryId: string;
    alternateEntryId?: string;
}

export interface PlayerContainerProps {
    entriesConfig?: EntriesConfig;
    video?: Media;
    playerTime?: number;
    setPlayerTime?: any;
    playerheight?: number;
    playerwidth?: number;
    hotspotList?: Hotspot[];
}

const useStyles = makeStyles((theme) => ({
    fullscreenButton: {
        position: 'absolute',
        top: theme.spacing(2),
        left: theme.spacing(2),
        zIndex: 9999,
        backgroundColor: "BLUE"
    }
}));

const PlayerContainer: FC<PlayerContainerProps> = ({
    entriesConfig,
    video,
    playerTime,
    setPlayerTime,
    playerheight,
    playerwidth,
    hotspotList,
    ...rest
}) => {

    const entryId = entriesConfig.entryId;
    const [playerId, setPlayerId] = useState("");
    const classes = useStyles();
    const playerContainerRef = useRef(null);

    const {
        playerTime$
    } = usePlayerUpdates(playerId);

    const {
        playerSeek,
        getPlayerInstance
    } = usePlayer(playerId);

    useEffect(() => {
        if (!playerId) {
            return;
        }

        const timeSubscription = playerTime$.subscribe(result => {
            setPlayerTime(result);
        });

        return () => {
            timeSubscription.unsubscribe();
        };
    }, [playerId, playerTime$]);// eslint-disable-line react-hooks/exhaustive-deps

    const handlePlayerLoaded = (data: { playerId: string }) => {
        const { playerId } = data;


        if (!playerId) {
            return;
        }

        setPlayerId(playerId);
    };

    const handleMediaLoaded = (entryId: string) => {
        if (!playerId) {
            return;
        }

        var durationSplit = video.duration.split(':');
        var durationTotalSeconds = (+durationSplit[0]) * 60 * 60 + (+durationSplit[1]) * 60 + (+durationSplit[2]);

        //  let initialTime = durationTotalSeconds * video.viewed_percentage * 10;
        let initialTime = 0;
        if (playerTime) {
            initialTime = playerTime;
        }

        if (initialTime > ((durationTotalSeconds * 1000) - 1000)) {
            initialTime = 0;
        }

        playerSeek({ seekTo: initialTime, pause: true });
    };

    const handleButtonClick = (javascript_action: string) => {
        if (javascript_action) {
            try {
                eval(javascript_action);
                console.log("Custom button action executed");
            } catch (error) {
                console.error("Error executing custom button action:", error);
            }
        }
    };

    const customizeConfig = (config: any) => {
        // DEVELOPER NOTICE - this is an optional method that lets you
        // customize the plaer config during loading.
        // if you don't need to customize, just remove it, just remove it
        // @ts-ignore
        const h = window["KalturaPlayer"].ui.preact.h;
        let hotspotButtons;
        if (hotspotList !== undefined) {
            hotspotButtons = hotspotList.map((hotspot) => {
                const buttonId = `customButton${hotspot.hotspot_id}`;
                const customButton = h("div", {
                    id: buttonId,
                    onClick: () => {
                        handleButtonClick(hotspot.javascript_action);
                    },
                    style: {
                        top: `${hotspot.position_y}%`,
                        left: `${hotspot.position_x}%`,
                        width: hotspot.width,
                        height: "auto",
                        background: hotspot.background_color,
                        display: "flex",
                        fontSize: hotspot.text_size,
                        fontWeight: "600",
                        overflow: "hidden",
                        color: hotspot.text_color,
                        opacity: 0.75,
                        padding: "0.5rem",
                        position: "absolute"
                    }
                }, hotspot.text);

                return customButton;
            });
        }

        const newConfig = {
            ...config,
            ui: {
                ...(config.ui || {}),
                uiComponents: [
                    ...((config.ui || {}).uiComponents || []),
                    {
                        label: "add custom",
                        presets: ["Playback"],
                        container: "VideoArea",
                        get: () => hotspotButtons, // Render custom button
                    }
                ]
            }
        };
        return newConfig;
    };

    const showButton = (hotspot: Hotspot, playerTime: number): boolean => {
        const { time_to_start_in_ms, time_to_finish_in_ms } = hotspot;
        return playerTime >= time_to_start_in_ms && playerTime <= time_to_finish_in_ms;
    };

    useEffect(() => {
        if (playerId && playerTime !== null && hotspotList !== undefined) {
            for (let hotspot of hotspotList) {
                const buttonId = `customButton${hotspot.hotspot_id}`;
                const buttonElement = document.getElementById(buttonId);
                if (buttonElement) {
                    const isButtonVisible = showButton(hotspot, playerTime);
                    buttonElement.style.display = isButtonVisible ? "block" : "none";
                    
                }
            }
        }
    }, [playerId, playerTime]);

    return (
        <div ref={playerContainerRef} style={{ position: 'relative', height: (playerheight || '100%'), width: (playerwidth || '100%') }}>
            <KalturaPlayer
                entryId={entryId}
                autoplay={true}
                onMediaLoaded={handleMediaLoaded}
                customizeConfig={customizeConfig}
                onPlayerLoaded={handlePlayerLoaded}
            />
        </div>
    );
};

PlayerContainer.propTypes = {
    playerTime: PropTypes.number,
};

PlayerContainer.defaultProps = {
    playerTime: null
};

export default PlayerContainer;

