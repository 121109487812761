import React from 'react';
import { FC } from 'react';
import { Box, Hidden, useMediaQuery, useTheme } from '@mui/material';
import { Channel } from 'src/types/channel';
import { ChannelViewInfoType } from 'src/types/channelView';
import makeStyles from '@mui/styles/makeStyles';

interface PublicLogoProps {
    channel: Channel;
    channelViewInfo: ChannelViewInfoType;
}

const useStyles = makeStyles((theme) => ({
    headerImage: {
        cursor: 'pointer',
        height: '2rem',
    }
}));

const PublicLogo: FC<PublicLogoProps> = ({ channel, channelViewInfo }) => {
    const theme = useTheme();
    const isMobile: boolean = useMediaQuery(theme.breakpoints.down('md'));
    const classes = useStyles();

    return (
        <Box>
            {isMobile ? (
                <>
                    <Hidden mdDown>
                        <img height="50px" alt="Logo" src={channel.public_mobile_logo} />
                    </Hidden>
                    <Hidden mdUp>
                        <img height="35px" alt="Logo" src={channel.public_mobile_logo} />
                    </Hidden>
                </>
            ) : (
                <>
                    {channel.public_logo ?
                        <>
                            {channelViewInfo && channelViewInfo.web_url ?
                                <a href={channelViewInfo.web_url} target="_blank">
                                    <img className={classes.headerImage} alt="Logo" src={channel.public_logo} />
                                </a>
                                :
                                <img className={classes.headerImage} alt="Logo" src={channel.public_logo} />
                            }


                        </>
                        :
                        <></>
                    }
                </>

            )}
        </Box>
    );
};

export default PublicLogo;
