import React, { useState } from 'react';
import type { FC } from 'react';
import { Box, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, styled, SvgIcon, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
    PlayCircle as PlayCircleIcon,
    Video as VideoIcon,
    MoreVertical as MoreVerticalIcon
} from 'react-feather';
import type { Media } from 'src/types/media';
import MobileVideoPlayerModal from './MobileVideoPlayerModal';
import { TreeItem, TreeView } from '@mui/lab';
import MobileMoreOptions from './MobileMoreOptions';
import { ChannelView } from 'src/types/channelView';
import Label from 'src/components/Label';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import VideoPasswordDialog from '../VideoPasswordDialog';

interface MobilePublicVideoBoxProps {
    className?: string;
    singleMedia?: Media;
    player_uiConfid: string;
    channelView: ChannelView;
}

const CardContentNoPadding = styled(CardContent)(`
  &:last-child {
    padding: 1rem;
  }
`);

const useStyles = makeStyles((theme) => ({
    root: {
        flexBasis: '80%',
        flexShrink: 0,
    },
    cardHeader: {
        '&.MuiCardHeader-root': {
            padding: 0
        }
    },
    moreIcon: {
        width: '16px',
        height: '16px'
    },
    videoIcon: {
        color: theme.palette.text.secondary
    },
    cardActions: {
        padding: 0,
        marginTop: '1rem',
        position: 'relative'
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: '2.5rem',
        wordBreak: 'break-word'
    },
    playCircleIcon: {
        position: 'absolute',
        top: "50%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        width: '2rem',
        color: theme.palette.grey[50],
    },
    firstTreeitem: {
        paddingBottom: '1.5rem',
        '& div': {
            '& div': {
                fontSize: '0.875rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.5rem',
            }
        }
    },
    card: {
        marginLeft: 2,
        marginRight: 2,
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 1px 8px rgba(0, 0, 0, 0.12)'
    },
    labelTime: {
        position: 'absolute',
        top: "85%",
        left: "80%",
        transform: "Translate(-50%,-50%)",
        color: theme.palette.grey[50],
        background: theme.palette.text.primary
    },
}));

const MobilePublicVideoBox: FC<MobilePublicVideoBoxProps> = ({ className, singleMedia, player_uiConfid, channelView, ...rest }) => {

    const config = getWSConfig();
    const classes = useStyles();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isMoreOptionsOpen, setMoreOptionsOpen] = useState<boolean>(false);
    const [playerTime, setPlayerTime] = useState<number | null>(null);
    const [openVideoPasswordDialog, setOpenVideoPasswordDialog] = useState<boolean>(false);

    const closeModal = (): void => {
        setModalOpen(false)
    }

    const openModal = (): void => {
        checkVideoAccess(singleMedia)
    }

    const switchMoreOptions = (): void => {
        if (!isMoreOptionsOpen) setMoreOptionsOpen(true)
        else setMoreOptionsOpen(false)
    }

    const handleCaptionTap = (captionTime: string): void => {
        var timeSplit = captionTime.split(':');
        var seconds = (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]);
        var miliseconds = seconds * 1000;
        setPlayerTime(miliseconds);
        openModal();
    }

    const checkVideoAccess = (singleMedia: Media): void => {
        let params = { "kaltura_video_id": singleMedia.entry_id, "channel_id": channelView.channel_id }
        axios.post(process.env.REACT_APP_SERVER_URL + '/channel_video/1.0/check/visibility/by/kaltura/id', params, config)
            .then(function (response) {
                if (response.data === "Public") {
                    setModalOpen(true)
                }
                else if (response.data === "Password") {
                    setOpenVideoPasswordDialog(true);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    };

    const handleOpenVideoIfCorrectPassword = (singlemedia: Media): void => {
        setModalOpen(true);
    };

    return (
        <Box className={classes.root}>

            <Card className={classes.card}>
                {isModalOpen &&
                    <MobileVideoPlayerModal
                        setPlayerTime={setPlayerTime}
                        playerTime={playerTime}
                        channelView={channelView}
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        media={singleMedia}>
                    </MobileVideoPlayerModal>}
                {openVideoPasswordDialog &&
                    <VideoPasswordDialog
                        channel_id={channelView.channel_id}
                        isOpen={openVideoPasswordDialog}
                        setOpen={setOpenVideoPasswordDialog}
                        media={singleMedia}
                        handleOpenVideoIfCorrectPassword={() => handleOpenVideoIfCorrectPassword(singleMedia)}
                    />
                }
                <Box position="relative">
                    <CardMedia
                        onClick={openModal}
                        component="img"
                        height="140"
                        image={singleMedia.big_thumbnailUrl}
                    />
                    <SvgIcon className={classes.playCircleIcon}
                        fontSize="large"
                        color="action"
                        onClick={openModal}
                    >
                        <PlayCircleIcon />
                    </SvgIcon>
                    <Label
                        className={classes.labelTime}
                    >
                        <Typography>
                            {singleMedia.duration}
                        </Typography>
                    </Label>

                </Box>

                <CardContentNoPadding sx={{ position: 'relative' }}>
                    <CardHeader
                        className={classes.cardHeader}
                        action={
                            <IconButton aria-label="settings" size="small" onClick={switchMoreOptions}>
                                <MoreVerticalIcon className={classes.moreIcon} />
                            </IconButton>
                        }
                        title={<Typography
                            variant="h6"
                            color="textPrimary"
                            className={classes.title}
                        >
                            {singleMedia.title}
                        </Typography>}
                    />
                    {singleMedia.captions && singleMedia.captions.length > 0 && (
                        <Box mt={1}>
                            <TreeView
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                                disableSelection={true}
                            >
                                <TreeItem className={classes.firstTreeitem} nodeId="-1"
                                    label={<Typography variant="caption" color="textPrimary">
                                        {"Encontrado en " + singleMedia.captions.length + " subtítulos"}

                                    </Typography>}
                                >
                                    {singleMedia.captions.map((caption, index) => {
                                        return (
                                            <TreeItem
                                                key={index}
                                                nodeId={index.toString()}
                                                onClick={() => handleCaptionTap(caption.startsAt)}
                                                label={
                                                    <Typography variant="caption" color="textPrimary">
                                                        {("[" + caption.startsAt + "]" + " " + caption.line)}
                                                    </Typography>
                                                }
                                            />
                                        )
                                    })}
                                </TreeItem>
                            </TreeView>
                        </Box>
                    )}
                    {isMoreOptionsOpen && <MobileMoreOptions setMoreOptionsOpen={setMoreOptionsOpen} channelView={channelView} video={singleMedia}></MobileMoreOptions>}
                    <CardActions className={classes.cardActions}>
                        <Box display="flex">
                            <Box>
                                <SvgIcon
                                    fontSize="small"
                                >
                                    <VideoIcon className={classes.videoIcon} />
                                </SvgIcon>
                            </Box>
                            <Box ml={1.5}>
                                <Typography
                                    color="textSecondary"
                                    variant="h6"
                                >
                                    Video
                                </Typography>
                            </Box>
                        </Box>
                    </CardActions>
                </CardContentNoPadding>
            </Card>
        </Box>
    );
};

export default MobilePublicVideoBox;
