import * as sanitizeHtml from 'sanitize-html';
import parse from "html-react-parser";
import axios from 'axios';
import useSettings from 'src/hooks/useSettings';
import organizationTagsEn from '../../compiled-organization-tags/organization-tags-en.json';
import organizationTagsEs from '../../compiled-organization-tags/organization-tags-es.json';
import { useIntl } from 'react-intl';

export const getWSConfig = () => {
    let token = localStorage.getItem('accessToken');
    let config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return config;
}

export const getSubDomainChannelView = () => {
    let prefix = window.location.host.split('.')[0];
    let subdomain = null;
    const subdomainmap = {
        fisiotv: {
            channelview: 30,
        },
        pestanaga: {
            channelview: 27,
        }
    };
    if ((prefix != null || prefix != undefined) && (subdomainmap[prefix])) {

        subdomain = subdomainmap[prefix].channelview;

    } else {
        subdomain = null;
    }
    return subdomain;
}

export const isIframe = () => {
    return window.location.pathname.startsWith("/organization/")
}

export const isAnIntegerValue = (text: string) => {
    return /\d/.test(text);
}

/**
 * 
 * @param htmlText a string with html tags and text
 * @returns a parsed html compiled with only safe tags
 */
export const cleanHtmlText = (htmlText) => {
    const cleanHtml = sanitizeHtml(htmlText, {
        allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 'br', 'li', 'ol', 'ul', 'u', 'h1', 'h2', 'h3', 'h4', 'h5'],
        allowedAttributes: {
            a: ['href', 'target']
        }
    });
    return parse(cleanHtml);
}

export const getAndStoreKalturaVideoPlayerId = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = { "organization_id": 0 }

    if (user && user.organization_id) {
        params = { "organization_id": user.organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/video/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_professional_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const getAndStoreKalturaVideoPlayerIdByChannelId = async (channel_id: number) => {
    let params = { "channel_id": channel_id }
    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/channel/1.0/get/kaltura/video/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_video_player_id_professional_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const getAndStoreKalturaPlaylistPlayerId = async () => {
    let user = JSON.parse(localStorage.getItem('user'));
    let params = { "organization_id": 0 }

    if (user && user.organization_id) {
        params = { "organization_id": user.organization_id }
    }

    try {
        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/organization/1.0/get/kaltura/playlist/player/id', params);
        if (response && response.data) {
            localStorage.setItem('kaltura_playlist_player_id_professional_portal', JSON.stringify(response.data));
            return JSON.stringify(response.data);
        }
    } catch (error) {
        console.log(error);
        return '';
    }
}

export const deleteKalturaVideoPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_professional_portal');
}

export const deleteKalturaPlaylistPlayerIdFromLocalStorage = () => {
    localStorage.removeItem('kaltura_video_player_id_professional_portal');
}

export const getOrganizationTag = (propertyPath: string): string => {
    const { settings } = useSettings();
    const intl = useIntl();
    const organizationTags = settings.language === 'en' ? organizationTagsEn : organizationTagsEs;
    const user = JSON.parse(localStorage.getItem('user'))

    if (user.organization_id !== undefined && user.organization_id !== null) {
        const organizationId = user.organization_id
        const organizationIdAsString = String(organizationId);
        const currentObject = organizationTags[organizationIdAsString]
        const properties = propertyPath.split('.');
        let value = currentObject;
        for (const property of properties) {
            if (value && typeof value === 'object' && property in value) {
                value = value[property];
            } else {
                return intl.formatMessage({ id: propertyPath })
            }
        }

        return (typeof value === 'object' || value === undefined) ? intl.formatMessage({ id: propertyPath }) : String(value);
    } else {
        return intl.formatMessage({ id: propertyPath })
    }

};
