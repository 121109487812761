import React, { useState } from 'react';
import type { FC } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    SvgIcon,
    Theme,
    Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    X as XIcon,
} from 'react-feather';
import { Channel } from 'src/types/channel';
import SubscriptionButton from 'src/views/utils/SubscriptionButton';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';


interface ModelBlockModalSuscribeProps {
    channel: Channel,
    isOpen: boolean;
    setOpen: any;
}

const ModelBlockModalSuscribe: FC<ModelBlockModalSuscribeProps> = ({ channel, isOpen, setOpen }) => {

    const useStyles = makeStyles((theme) => ({
        root: {},
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        cancelButton: {
            color: 'black',
            backgroundColor: 'white',
            "&:hover": {
                background: "#efefef"
            }
        },
        signUpButton: {
            marginLeft: '1rem',
            width: '9rem',
            backgroundColor: '#1CA58A',
            color: theme.palette.background.default,
        },
    }));
    const history = useHistory();
    const classes = useStyles();
    const handleClose = (): void => {
        setOpen(false);
    };
    const goToRegister = (): void => {
        history.push('/register');
    };

    return (
        <Dialog
            maxWidth="sm"
            className={classes.container}
            onClose={handleClose}
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle >
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="h3"
                        color="textPrimary"
                    >
                        <FormattedMessage id="Channel_PublicChannelDetailView_ModelBlockModalSubscribe.request_access" />
                    </Typography>

                    <IconButton onClick={handleClose} size="large">
                        <SvgIcon fontSize="small">
                            <XIcon />
                        </SvgIcon>
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <FormattedMessage id="Channel_PublicChannelDetailView_ModelBlockModalSubscribe.3d_access_requirements" />{channel.name}
                </DialogContentText>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={5}
                >
                    <Button
                        color="secondary"
                        size="large"
                        variant="contained"
                        className={classes.cancelButton}
                        onClick={handleClose}
                    >
                        <FormattedMessage id="Channel_PublicChannelDetailView_ModelBlockModalSubscribe.cancel" />
                    </Button>
                    <Button className={classes.signUpButton} onClick={() => goToRegister()} variant="contained"><FormattedMessage id="Channel_PublicChannelDetailView_ModelBlockModalSubscribe.register" /></Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default ModelBlockModalSuscribe;
