import React, { useState } from 'react';
import type { FC } from 'react';
import { SvgIcon, Box, Typography, IconButton, Card, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
    Box as BoxIcon,
} from 'react-feather';
import { Model } from 'src/types/model';
import { Channel } from 'src/types/channel';
import { FormattedMessage } from 'react-intl';
import ModelBlockModalSuscribe from '../ModelBlockModalSuscribe';

interface MobileDrawerModelCardProps {
    className?: string;
    singleModel?: Model;
    channel: Channel;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        title: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            height: '2.5rem',
            wordBreak: 'break-word'
        },
        videoIcon: {
            color: theme.palette.text.secondary
        },
    }),
);

const MobileDrawerModelCard: FC<MobileDrawerModelCardProps> = ({ className, singleModel, channel }) => {
    const classes = useStyles();
    const [isOpenlSuscribeDialog, setOpenSuscribeDialog] = useState<boolean>(false);

    const handleOpenSuscribeDialog = (): void => {
        setOpenSuscribeDialog(true);
    };

    return (
        <Box position="relative">
            <Card sx={{ display: 'flex' }} >
                <Box flex="50%" padding={2}>
                    <Box display="flex" gap={1}>
                        <Box>
                            <SvgIcon
                                fontSize="small"
                            >
                                <BoxIcon className={classes.videoIcon} />
                            </SvgIcon>
                        </Box>
                        <Box>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                <FormattedMessage id="Channel_ChannelDetailView_ModelBox.3d_model" />

                            </Typography>
                        </Box>
                    </Box>
                    <Box position="relative">
                        <CardMedia
                            component="img"
                            height="90"
                            image={singleModel.thumbnail_url}
                            onClick={() => handleOpenSuscribeDialog()}
                        />
                    </Box>
                </Box>
                <Box flex="50%" padding={2} display="flex" flexDirection="column" gap={1} mt={1}>
                    <Box>
                        <Typography component="div" variant="h6" className={classes.title}>
                            {singleModel.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <IconButton aria-label="play/pause">
                        </IconButton>
                    </Box>
                </Box>
                {isOpenlSuscribeDialog &&
                    <ModelBlockModalSuscribe channel={channel} isOpen={isOpenlSuscribeDialog} setOpen={setOpenSuscribeDialog} />
                }
            </Card>
        </Box>

    );
}

export default MobileDrawerModelCard;