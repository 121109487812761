import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import {
    Tab,
    Tabs,
    Divider,
    Typography,
    Collapse,
    IconButton,
    Card,
    Grid,
    CardContent,
    SvgIcon,
    Box,
    Link,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ChannelViewInfoLink, ChannelViewInfoType, ChannelViewTab } from 'src/types/channelView';

import { Channel } from 'src/types/channel';

import { Alert } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';
import { alertStatus } from 'src/types/alertStatus';
import {
    MapPin as MapPinIcon,
    Phone as PhoneIcon,
    Mail as MailIcon,
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    Linkedin as LinkedinIcon,
    Instagram as InstagramIcon,
    Link as LinkIcon,

} from 'react-feather';
interface PublicChannelViewInfoProps {
    channel: Channel;
    channelViewInfo: ChannelViewInfoType;
}

const useStyles = makeStyles(() => ({
    root: {},
    contact: {
        marginTop: "1.5rem",
    },
    divider: {
        marginTop: '0rem',
        marginLeft: '0rem',
        marginRight: '0rem',
        marginBottom: '1.5rem',
        zIndex: 1
    },
    dividerContact: {
        marginTop: '0rem',
        marginLeft: '0rem',
        marginRight: '0rem',
        marginBottom: '1rem',
        zIndex: 1
    },
    description: {
        marginTop: "1.5rem",
        marginBottom: "1.5rem"
    },
    descriptionTitle: {
        marginBottom: "0.5rem",
        Weight: 600,
    },
    linkTitle: {
        marginBottom: "0.5rem",
        Weight: 600,
    },
    contactTitle: {
        marginBottom: "0.5rem",
        Weight: 600,
    },
    Icons: {
        left: '0rem',
        top: '0rem',
        marginLeft: '0rem',
    },
    mapPinBox: {
        display: 'flex',
        marginBottom: '1rem',
    },
    phone1Box: {
        display: 'flex',
        marginBottom: '1rem',
    },
    phone2Box: {
        display: 'flex',
        marginBottom: '1rem',
    },
    mailBox: {
        display: 'flex',
        marginBottom: '1rem',
    },
    direction: {
        marginLeft: '2rem',
    },
    phone1: {
        marginLeft: '2rem',
    },
    phone2: {
        marginLeft: '2rem',
    },
    mail: {
        marginLeft: '2rem',
    },
    facebookBox: {
        display: 'flex',
        marginBottom: '1rem',
    },
    twitterBox: {
        display: 'flex',
        marginBottom: '1rem',
    },
    linkedinBox: {
        display: 'flex',
        marginBottom: '1rem',
    },
    instagramBox: {
        display: 'flex',
        marginBottom: '1rem',
    },
    socials: {
        marginLeft: '2rem',
    }
}));

const PublicChannelViewInfo: FC<PublicChannelViewInfoProps> = ({ channel, channelViewInfo }) => {
    const classes = useStyles();
    const config = getWSConfig();

    return (
        <Grid container spacing={3}>
            <Grid item xs={9}>
                <Card
                    className={classes.description}
                >
                    <CardContent>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            className={classes.descriptionTitle}
                        >
                            <FormattedMessage id="Channel_PublicChannelDetailView_PublicChannelViewInfo.description" />
                        </Typography>
                        <Divider className={classes.divider} />
                        <Typography
                            variant="body2"
                            color="textPrimary"
                        >
                            {channelViewInfo && channelViewInfo.channel_view_description}
                        </Typography>
                    </CardContent>
                </Card>
                <Card>
                    <CardContent>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            className={classes.linkTitle}
                        >
                            <FormattedMessage id="Channel_PublicChannelDetailView_PublicChannelViewInfo.links" />
                        </Typography>
                        <Divider className={classes.divider} />
                        <Grid
                            container
                            spacing={3}
                        >
                            {channelViewInfo && channelViewInfo.channelViewInfoLinkList.map((link) => {
                                return (
                                    <Grid
                                        item
                                        key={link.channel_view_info_links_id}
                                        xs={12}
                                        sm={12}
                                        md={6}
                                    >
                                        {
                                            link.text &&
                                            <Link
                                                href={link.link}
                                                variant="body2"
                                            >
                                                {link && link.text}
                                            </Link>
                                        }
                                        {
                                            !link.text &&
                                            <Link
                                                href={link.link}
                                                variant="body2"
                                            >
                                                {link && link.link}
                                            </Link>
                                        }
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </CardContent>
                </Card>

            </Grid>
            <Grid item xs={3}>
                <Card
                    className={classes.contact}
                >
                    <CardContent>
                        <Typography
                            color="textPrimary"
                            variant="h5"
                            className={classes.contactTitle}
                        >
                            <FormattedMessage id="Channel_PublicChannelDetailView_PublicChannelViewInfo.contact" />
                        </Typography>
                        <Divider className={classes.divider} />

                        <Box
                            className={classes.mapPinBox}
                        >
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                <MapPinIcon />
                            </SvgIcon>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                className={classes.direction}
                            >
                                {channelViewInfo && channelViewInfo.direction}
                            </Typography>
                        </Box>

                        <Box
                            className={classes.phone1Box}
                        >
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                <PhoneIcon />
                            </SvgIcon>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                className={classes.phone1}
                            >
                                {channelViewInfo && channelViewInfo.phone_number_one}
                            </Typography>
                        </Box>

                        {channelViewInfo && channelViewInfo.phone_number_two &&
                            <Box
                                className={classes.phone2Box}
                            >
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <PhoneIcon />
                                </SvgIcon>
                                <Typography
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.phone2}
                                >
                                    {channelViewInfo && channelViewInfo.phone_number_two}
                                </Typography>
                            </Box>
                        }
                        <Box
                            className={classes.mailBox}
                        >
                            <SvgIcon
                                fontSize="small"
                                color="action"
                            >
                                <MailIcon />
                            </SvgIcon>
                            <Typography
                                variant="body2"
                                color="textPrimary"
                                className={classes.mail}
                            >
                                {channelViewInfo && channelViewInfo.email}
                            </Typography>
                        </Box>
                    </CardContent>
                    <Divider className={classes.dividerContact} />
                    <CardContent>
                        {channelViewInfo && channelViewInfo.facebook_account &&
                            <Box
                                className={classes.facebookBox}
                            >
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <FacebookIcon />
                                </SvgIcon>
                                <Link
                                    href={channelViewInfo.facebook_account}
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.socials}
                                >
                                    Facebook
                                </Link>

                            </Box>
                        }
                        {channelViewInfo && channelViewInfo.facebook_account &&
                            <Box
                                className={classes.facebookBox}
                            >
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <LinkIcon />
                                </SvgIcon>
                                <Link
                                    href={channelViewInfo.web_url}
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.socials}
                                >
                                    Web
                                </Link>

                            </Box>
                        }
                        {channelViewInfo && channelViewInfo.twitter_account &&
                            <Box
                                className={classes.twitterBox}
                            >
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <TwitterIcon />
                                </SvgIcon>
                                <Link
                                    href={channelViewInfo.twitter_account}
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.socials}
                                >
                                    Twitter
                                </Link>
                            </Box>
                        }
                        {channelViewInfo && channelViewInfo.linkedin_account &&
                            <Box
                                className={classes.linkedinBox}
                            >
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <LinkedinIcon />
                                </SvgIcon>
                                <Link
                                    href={channelViewInfo.linkedin_account}
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.socials}
                                >
                                    Linkedin
                                </Link>
                            </Box>
                        }
                        {channelViewInfo && channelViewInfo.instagram_account &&
                            <Box
                                className={classes.instagramBox}
                            >
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                >
                                    <InstagramIcon />
                                </SvgIcon>
                                <Link
                                    href={channelViewInfo.instagram_account}
                                    variant="body2"
                                    color="textPrimary"
                                    className={classes.socials}
                                >
                                    Instagram
                                </Link>
                            </Box>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default PublicChannelViewInfo;