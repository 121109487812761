import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Button, SvgIcon, Hidden, Box, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import { Channel } from 'src/types/channel';
import Logo from 'src/components/Logo';
import {
    Facebook as FacebookIcon,
    Twitter as TwitterIcon,
    Linkedin as LinkedinIcon,
    Instagram as InstagramIcon,
    Link as LinkIcon,
} from 'react-feather';
import { ChannelViewInfoType } from 'src/types/channelView';
import PublicLogo from 'src/components/PublicLogo';

interface FooterProps {
    channelViewInfo: ChannelViewInfoType;
    channel: Channel;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            position: "relative",
            left: 0,
            bottom: 0,
            right: 0,
        },
        header: {
            backgroundColor: theme.palette.background.default,
            boxShadow: 'none',
            zIndex: theme.zIndex.drawer + 100,
            margin: 'auto',
            width: '100%',
        },
        signUpButton: {
            marginLeft: '1rem',
            width: '9rem',
            backgroundColor: '#1CA58A',
            color: theme.palette.background.default,
        },
        loginButton: {
            width: '7rem',
            backgroundColor: theme.palette.background.default,
            color: '#535961'
        },
        buttonsDiv: {
            marginLeft: "auto"
        },
        appBar: {
            backgroundColor: theme.palette.background.default
        },
        icons: {
            marginRight: "2rem",
        },
    }),
);

const Footer: FC<FooterProps> = ({ channelViewInfo, channel }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const mobile: boolean = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar className={classes.header}>
                    <Box display={matches ? 'none' : 'block'}>
                        <PublicLogo channel={channel} channelViewInfo={channelViewInfo} />
                    </Box>
                    <Box className={classes.buttonsDiv}>
                        {channelViewInfo && channelViewInfo.facebook_account &&
                            <a href={channelViewInfo.facebook_account} target="_blank">
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                    className={classes.icons}
                                >
                                    <FacebookIcon />
                                </SvgIcon>
                            </a>
                        }
                        {channelViewInfo && channelViewInfo.web_url &&
                            <a href={channelViewInfo.web_url} target="_blank">
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                    className={classes.icons}
                                >
                                    <LinkIcon />
                                </SvgIcon>
                            </a>
                        }
                        {channelViewInfo && channelViewInfo.linkedin_account &&
                            <a href={channelViewInfo.linkedin_account} target="_blank">
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                    className={classes.icons}
                                >
                                    <LinkedinIcon />
                                </SvgIcon>
                            </a>
                        }
                        {channelViewInfo && channelViewInfo.twitter_account &&
                            <a href={channelViewInfo.twitter_account} target="_blank">
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                    className={classes.icons}
                                >
                                    <TwitterIcon />
                                </SvgIcon>
                            </a>
                        }
                        {channelViewInfo && channelViewInfo.instagram_account &&
                            <a href={channelViewInfo.instagram_account} target="_blank">
                                <SvgIcon
                                    fontSize="small"
                                    color="action"
                                    className={classes.icons}
                                >
                                    <InstagramIcon />
                                </SvgIcon>
                            </a>
                        }
                    </Box>
                </Toolbar>
            </AppBar>
        </div>
    );

}

export default Footer;