import React, {
    useRef,
    useState
} from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Avatar from 'react-avatar';
import { Box, ButtonBase, Hidden, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { ProfessionalMedic } from 'src/types/professionalMedic';
import useAuth from 'src/hooks/useAuth';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1)
    },
    popover: {
        width: 200
    }
}));

const Account: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const ref = useRef<any>(null);
    const { logout } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setOpen] = useState<boolean>(false);
    const professionalMedic: ProfessionalMedic = JSON.parse(localStorage.getItem('user'));
    const alternativeLogout = localStorage.getItem('alternativeLogout');

    const disableCursorInteractionStyle = {
        cursor: localStorage.getItem('alternativeLogout') === 'true' ? 'default' : 'pointer'
    };

    const handleOpen = (): void => {
        if (alternativeLogout !==  'true') {
            setOpen(true);
        }
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleLogout = async (): Promise<void> => {
        try {
            handleClose();
            logout();
            history.push('/login/' + professionalMedic.organization_id);
        } catch (err) {
            console.error(err);
            enqueueSnackbar(<FormattedMessage id="topbar_enqueue_account.unable_logout" />, {
                variant: 'error'
            });
        }
    };

    return <>
        <Box
            display="flex"
            alignItems="center"
            component={ButtonBase}
            onClick={handleOpen}
            // @ts-ignore
            ref={ref}
            style={disableCursorInteractionStyle}
        >
            <Avatar
                name={professionalMedic.name + " " + professionalMedic.last_name}
                maxInitials={2}
                size="30"
                round={true}
                color="#E0E0E0"
                className={classes.avatar}
            />
            <Hidden mdDown>
                <Typography
                    variant="h6"
                    color="textPrimary"
                >
                    {professionalMedic.name} {" "} {professionalMedic.last_name}
                </Typography>
            </Hidden>
        </Box>
        <Menu
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            anchorEl={ref.current}
            open={isOpen}
        >
            <MenuItem onClick={handleLogout}>
                <FormattedMessage id="topbar_account.exit" />
            </MenuItem>
        </Menu>
    </>;
}

export default Account;
