import { FC, useEffect } from 'react'
import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { ChannelViewSubsection, ChannelViewSection, ChannelViewTab, IDTree } from 'src/types/channelView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';
import { Channel } from 'src/types/channel';
import PublicContent from './PublicContent';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '2.25rem',
        paddingLeft: '2rem',
        paddingRight: '1.5rem',
    },
    treeView: {
        display: 'block',
        paddingTop: '1.5rem',
        paddingLeft: '1rem',
        width: '16.5rem',
        height: '36.5rem',
        backgroundColor: "#FFFFFF",
    },
    menuItem: {
        marginBottom: '0.5rem',
        '& a': {
            paddingLeft: '0rem',
            '& span': {
                '& span': {
                    marginLeft: '1.5rem',
                },
            }
        }
    },
    labelText: {
        fontSize: '1rem',
        flexGrow: 1,
    },
}));

interface PublicInnerNavBarProps {
    className?: string;
    channelViewSectionList: ChannelViewSection[];
    currentTab: ChannelViewTab;
    channel: Channel;
    selectAll: boolean;
    setSelectAll: any;
    VideoChecked: boolean;
    ModelsChecked: boolean;
    query: string;
    setQuery: any;
    languageFilter: number;
    setLanguageFilter: any;
    filesChecked: boolean;
    externalLinksChecked: boolean;
}

const StyledTreeItem = withStyles({
    root: {
        paddingBottom: "1rem",
        "&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
            color: "#1CA58A",
            opacity: "1",
        },
        color: "#546e7a",
        fontWeight: 600,
    },
    content: {
        flexDirection: "row-reverse"
    },
    label: {
        color: "#546e7a",
        textTransform: "none",
        fontWeight: 600,
        lineHeight: 21,

    },
})(TreeItem);

const PublicInnerNavBar: FC<PublicInnerNavBarProps> = ({
    className,
    languageFilter,
    setLanguageFilter,
    query,
    setQuery,
    VideoChecked,
    ModelsChecked,
    channelViewSectionList,
    currentTab,
    channel,
    selectAll,
    setSelectAll,
    filesChecked,
    externalLinksChecked,
    ...rest }) => {
    var treeItemId = 1;
    const [selectedItem, setSelectedItem] = useState<ChannelViewSection | ChannelViewSubsection>({ channel_view_section_id: 0, channel_view_tab_id: 0, name: "", channelViewSubSectionList: [], sequence_alphabetical: null });
    const [SectionAll, setSectionAll] = useState<ChannelViewSection>({ channel_view_section_id: 0, channel_view_tab_id: 0, name: "", channelViewSubSectionList: [], sequence_alphabetical: null });
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = useState<string>("0");
    const [listOfTreeNodeWithCorrespondentSection, setListOfTreeNodeWithCorrespondentSection] = useState<IDTree[]>([]);
    const [innerNavBar] = useState<string>('grid');
    const [noInnerNavBar] = useState<string>('nogrid');

    async function incrementTreeItemId() {
        treeItemId++;
    }

    const renderChannelViewSubSectionList = (channelViewSubsectionList: ChannelViewSubsection[], classes) => {
        return channelViewSubsectionList.map(subSection => {
            incrementTreeItemId()

            let id: IDTree = { TreeID: 0, SectionID: 0, IsSectionOtherWiseIsSubsection: false };

            id.TreeID = treeItemId;
            id.SectionID = subSection.channel_view_sub_section_id;
            id.IsSectionOtherWiseIsSubsection = false;


            var IsTheTreeNodeInTheList = false;
            listOfTreeNodeWithCorrespondentSection.map(index => {
                if (index.TreeID === id.TreeID) {
                    IsTheTreeNodeInTheList = true;
                }
            });
            if (!IsTheTreeNodeInTheList) {
                listOfTreeNodeWithCorrespondentSection.push(id);
            }
            return (
                <StyledTreeItem
                    key={treeItemId}
                    nodeId={treeItemId.toString()}
                    label={
                        <Typography
                            variant="subtitle2"
                            className={classes.labelText}
                        >
                            {subSection.name}
                        </Typography>
                    }
                    children={undefined}
                    className={classes.menuItem}
                />
            );
        });
    }

    const renderChannelViewSectionList = (channelViewSectionList: ChannelViewSection[], classes) => {

        return channelViewSectionList.map(section => {
            let children = undefined;

            if (section.channelViewSubSectionList && section.channelViewSubSectionList.length > 0) {
                children = renderChannelViewSubSectionList(section.channelViewSubSectionList, classes);
            }
            incrementTreeItemId()

            let id: IDTree = { TreeID: 0, SectionID: 0, IsSectionOtherWiseIsSubsection: true };
            id.TreeID = treeItemId;
            id.SectionID = section.channel_view_section_id;
            id.IsSectionOtherWiseIsSubsection = true;

            var IsTheTreeNodeInTheList = false;
            listOfTreeNodeWithCorrespondentSection.map(index => {
                if (index.TreeID === id.TreeID) {
                    IsTheTreeNodeInTheList = true;
                }
            });
            if (!IsTheTreeNodeInTheList) {
                listOfTreeNodeWithCorrespondentSection.push(id);
            }

            return (
                <StyledTreeItem
                    key={treeItemId}
                    nodeId={treeItemId.toString()}
                    label={
                        <Typography
                            variant="subtitle2"
                            className={classes.labelText}
                        >
                            {section.name}
                        </Typography>
                    }
                    children={children}
                    className={classes.menuItem}
                    classes={{
                        label: classes.label
                    }}
                />
            );
        });
    };

    const serachSectionInTree = (key): void => {
        var FindTreeIDinTheListofPairs = listOfTreeNodeWithCorrespondentSection.find(element => element.TreeID === parseInt(key));

        if (key === "0") {
            setSelectedItem(SectionAll);
        }
        //Is subsection
        else if (!FindTreeIDinTheListofPairs.IsSectionOtherWiseIsSubsection) {

            channelViewSectionList.map(section => {
                if (section.channelViewSubSectionList && section.channelViewSubSectionList.length > 0) {

                    var TreeNodeChannelViewSubSection: ChannelViewSubsection = section.channelViewSubSectionList.find(element => element.channel_view_sub_section_id === FindTreeIDinTheListofPairs.SectionID)

                    if (TreeNodeChannelViewSubSection !== undefined) {
                        setSelectedItem(TreeNodeChannelViewSubSection);
                    }
                }
            });
        }
        else {
            var TreeNodeChannelViewSection = channelViewSectionList.find(element => element.channel_view_section_id === FindTreeIDinTheListofPairs.SectionID);
            setSelectedItem(TreeNodeChannelViewSection);
        }


    }
    const handleSelect = (event, key) => {
        setSelectAll(false);
        setSelected(key);
        serachSectionInTree(key);
    };
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    useEffect(() => {
        if (selectAll === true) {
            setSelected("0");
            serachSectionInTree("0");
            setQuery(query);
        }
    }, [selectAll]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setListOfTreeNodeWithCorrespondentSection([]);
    }, [currentTab]);// eslint-disable-line react-hooks/exhaustive-deps

    const classes = useStyles();
    if ((channelViewSectionList.length < 1) || (channelViewSectionList.length === 1 && channelViewSectionList[0].channelViewSubSectionList.length <= 1)) {
        return (
            <PublicContent
                currentTab={currentTab}
                selectedSectionOrSubSection={SectionAll}
                channel={channel}
                setSelectAll={setSelectAll}
                VideoChecked={VideoChecked}
                ModelsChecked={ModelsChecked}
                query={query}
                mode={noInnerNavBar}
                setQuery={setQuery}
                languageFilter={languageFilter}
                filesChecked={filesChecked}
                externalLinksChecked={externalLinksChecked}
            />
        )
    }

    else {
        let id: IDTree = { TreeID: 0, SectionID: 0, IsSectionOtherWiseIsSubsection: true };

        var IsTheTreeNodeInTheList = false;
        listOfTreeNodeWithCorrespondentSection.map(index => {
            if (index.TreeID === id.TreeID) {
                IsTheTreeNodeInTheList = true;
            }
        });
        if (!IsTheTreeNodeInTheList) {
            listOfTreeNodeWithCorrespondentSection.push(id);
        }
        return (
            <div>
                <div style={{ float: 'left', marginTop: '0rem', paddingTop: '0rem', paddingLeft: '0rem', paddingRight: '1.5rem', }}>
                    <TreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        className={classes.treeView}
                        selected={selected}
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        onNodeSelect={handleSelect}
                    >
                        <StyledTreeItem
                            key={0}
                            nodeId={"0"}
                            label={
                                <Typography
                                    variant="subtitle2"
                                    className={classes.labelText}
                                >
                                    {"Todo"}
                                </Typography>
                            }
                            className={classes.menuItem}
                        />
                        {renderChannelViewSectionList(channelViewSectionList, classes)}
                    </TreeView>

                </div>
                <PublicContent
                    currentTab={currentTab}
                    selectedSectionOrSubSection={selectedItem}
                    channel={channel}
                    setSelectAll={setSelectAll}
                    VideoChecked={VideoChecked}
                    ModelsChecked={ModelsChecked}
                    query={query}
                    mode={innerNavBar}
                    setQuery={setQuery}
                    languageFilter={languageFilter}
                    filesChecked={filesChecked}
                    externalLinksChecked={externalLinksChecked}
                />
            </div>

        );
    }
}

export default PublicInnerNavBar