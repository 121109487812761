import { useEffect, type FC, useState } from 'react';
import { SvgIcon, Box, Typography, IconButton, Card, CardMedia } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import {
    ExternalLink as ExternalLinkIcon,
} from 'react-feather';
import { ExternalLinkResource } from 'src/types/external_link_resource';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';

interface MobileDrawerExternalLinkCardProps {
    external_link: ExternalLinkResource;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        title: {
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            "-webkit-line-clamp": 2,
            "-webkit-box-orient": "vertical",
            height: '2.5rem',
            wordBreak: 'break-word'
        },
        videoIcon: {
            color: theme.palette.text.secondary
        },
        boxFileIcon: {
            position: 'relative',
            display: "flex",
            backgroundColor: theme.palette.neutral[400],
            aspectRatio: '16 / 9',
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "0.25rem",
            cursor: 'pointer',
            width: '100%',
            height: 'auto',
        },
        fileIcon: {
            width: '5rem',
            height: '5rem',
            color: theme.palette.primary.contrastText,
        },
        image: {
            width: '100%',
            height: 'auto',
            aspectRatio: '16 / 9',
            cursor: 'pointer',
            backgroundColor: theme.palette.background.dark,
        },
    }),
);

const MobileDrawerExternalLinkCard: FC<MobileDrawerExternalLinkCardProps> = ({ external_link }) => {
    const classes = useStyles();

    const openExternalLink = (): void => {
        window.open(external_link.link);
    };

    const config = getWSConfig();
    const [externalLinkThumbnailLink, setExternalLinkThumbnailLink] = useState(null);
    const getExternalLinkThumbnailLink = (): void => {
        let params = {}
        params["external_link_resource_id"] = external_link.external_link_resource_id;
        axios.post(process.env.REACT_APP_SERVER_URL + '/external_link_resource/1.0/get/thumbnail_link/public', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setExternalLinkThumbnailLink(response.data);
                }
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
                return null;
            });
    };
    useEffect(() => {
        if (external_link.thumbnail_extension) getExternalLinkThumbnailLink();
    }, []);

    return (
        <Box position="relative">
            <Card sx={{ display: 'flex' }} >
                <Box flex="50%" padding={2}>
                    <Box display="flex" gap={1}>
                        <Box>
                            <SvgIcon
                                fontSize="small"
                            >
                                <ExternalLinkIcon className={classes.videoIcon} />
                            </SvgIcon>
                        </Box>
                        <Box>
                            <Typography
                                color="textSecondary"
                                variant="h6"
                            >
                                {"Link"}                            
                            </Typography>
                        </Box>
                    </Box>
                    <Box position="relative">
                        <Box
                            onClick={() => openExternalLink()}
                            height={90}
                            className={classes.boxFileIcon}>
                            {(external_link.thumbnail_extension) && <CardMedia className={classes.image}
                                image={externalLinkThumbnailLink}
                            >
                            </CardMedia>}
                            {(!external_link.thumbnail_extension) && (external_link.thumbnail_url) && <CardMedia className={classes.image}
                                image={external_link.thumbnail_url}
                            >
                            </CardMedia>}
                            {(!external_link.thumbnail_extension) && (!external_link.thumbnail_url) && <Box className={classes.boxFileIcon}> 
                                <ExternalLinkIcon className={classes.fileIcon} />
                            </Box>}
                        </Box>
                    </Box>
                </Box>
                <Box flex="50%" padding={2} display="flex" flexDirection="column" gap={1} mt={1}>
                    <Box>
                        <Typography component="div" variant="h6" className={classes.title}>
                            {external_link.title}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                        <IconButton aria-label="play/pause">
                        </IconButton>
                    </Box>
                </Box>
            </Card>
        </Box>

    );
}

export default MobileDrawerExternalLinkCard;