import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import {
    Hidden,
} from '@mui/material';
import type { Media } from 'src/types/media';
import VideoPlayerDialogLarge from './VideoPlayerDialogLarge';
import VideoPlayerDialogMedium from './VideoPlayerDialogMedium';
import VideoPlayerDialogSmall from './VideoPlayerDialogSmall';
import axios from 'axios';
import { getWSConfig } from '../utils/Utils';

export interface VideoPlayerDialogProps {
    isOpen?: boolean;
    onClose?: () => void;
    video?: Media;
    playerTime?: number;
    setPlayerTime?: any;
    channelViewId?: number;
}

const VideoPlayerDialog: FC<VideoPlayerDialogProps> = ({
    isOpen,
    onClose,
    video,
    playerTime,
    setPlayerTime,
    channelViewId,
}) => {

    const config = getWSConfig();
    const [downloadAttachmentsLink, setDownloadAttachmentsLink] = useState<string>(null)
    const [loading, setLoading] = useState<boolean>(true)

    const getDownloadAttachmentsLink = (): void => {
        let params = { media_id: video.entry_id }
        axios.post(process.env.REACT_APP_SERVER_URL + '/media/1.0/get/attachments', params, config)
            .then(function (response) {
                if (response && response.data) {
                    setDownloadAttachmentsLink(response.data)
                }
            })
            .catch(function (error) {
                console.log("getDownloadAttachmentsLink error: ", error)
            })
            .finally(() => {
                setLoading(false)
            });
    }

    useEffect(() => {
        getDownloadAttachmentsLink();
    }, []);

    if (loading === true) {
        return null;
    }
    else {
        return (
            <>
                <Hidden only={['sm', 'xs']}>
                    <VideoPlayerDialogLarge isOpen={isOpen} onClose={onClose} video={video}
                        playerTime={playerTime} setPlayerTime={setPlayerTime} channelViewId={channelViewId}
                        downloadAttachmentsLink={downloadAttachmentsLink} />
                </Hidden>
                <Hidden only={['xs', 'md', 'lg', 'xl']}>
                    <VideoPlayerDialogMedium isOpen={isOpen} onClose={onClose} video={video}
                        playerTime={playerTime} setPlayerTime={setPlayerTime} channelViewId={channelViewId}
                        downloadAttachmentsLink={downloadAttachmentsLink} />
                </Hidden>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <VideoPlayerDialogSmall isOpen={isOpen} onClose={onClose} video={video}
                        playerTime={playerTime} setPlayerTime={setPlayerTime} channelViewId={channelViewId}
                        downloadAttachmentsLink={downloadAttachmentsLink} />
                </Hidden>
            </>
        );
    }

};

export default VideoPlayerDialog;

