import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Card, } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import type { Media } from 'src/types/media';
import VideoPlayerDialog from 'src/views/VideoPlayer/VideoPlayerDialog';
import { TreeItem, TreeView } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import VideoPasswordDialog from './VideoPasswordDialog';
import PublicVideoBoxInfo from './PublicVideoBoxInfo';
import axios from 'axios';
import { getWSConfig } from 'src/views/utils/Utils';

interface PublicVideoBoxProps {
    className?: string;
    singlemedia?: Media;
    channelView: number;
    channel_id: number;
}

const useStyles = makeStyles((theme) => ({
    root: {
    },
    titleMedia: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginBottom: '0.5rem',
        height: '2.5rem',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    description: {
        display: `-webkit-box`,
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    image: {
        width: '100%',
        height: 'auto',
        aspectRatio: '16 / 9',
        cursor: 'pointer',
        backgroundColor: theme.palette.background.dark,
    },
    playCircleIcon: {
        position: 'absolute',
        visibility: 'hidden',
        pointerEvents: 'none',
        top: "50%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        width: '2rem',
        color: 'white',
        cursor: 'pointer',
    },
    videoIcon: {
        position: 'relative',
        left: '0rem',
        top: '0rem',
        marginLeft: '0rem',
        color: theme.palette.text.secondary,
        float: 'left',
    },
    videoText: {
        overflow: 'hidden',
    },
    labelTime: {
        position: 'absolute',
        pointerEvents: 'none',
        top: "85%",
        left: "80%",
        transform: "Translate(-50%,-50%)",
        color: 'white',
        cursor: 'pointer',
        background: 'rgba(0, 0, 51, 0.87)',
    },
    divImage: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        marginBottom: '1rem',
        '&:hover': {
            '& svg': {
                visibility: 'visible',
            },
        },
    },
    treeitems: {
        '& div': {
            '& div': {
                fontSize: '0.875rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.5rem',
            }
        }
    },
    firstTreeitem: {
        paddingLeft: '1rem',
        paddingBottom: '1.5rem',
        '& div': {
            '& div': {
                fontSize: '0.875rem',
                paddingRight: '0.5rem',
                paddingBottom: '0.5rem',
            }
        }
    },
    divider: {
        marginTop: '0rem',
        marginLeft: '0rem',
        marginRight: '0rem',
    },
    labelVideoText: {
        marginLeft: '0.5rem',
        background: 'grey-300',
    },
    actionArea: {
        color: 'TextPrimary',
        '&:hover $focusHighlight': {
            opacity: 0.5,
            color: 'TextPrimary',
        },
    },
    focusHighlight: {},
    lockIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "40%",
        left: "50%",
        transform: "Translate(-50%,-40%)",
        width: '2rem',
        color: 'white',
    },
    buttonIcon: {
        visibility: 'hidden',
        pointerEvents: 'none',
        position: 'absolute',
        top: "70%",
        left: "50%",
        transform: "Translate(-50%,-50%)",
        height: '1.5rem',
        width: '2rem',
        color: 'white',
    },
    lockDiv: {
        position: 'relative',
        width: '100%',
        height: 'auto',
        '&:hover': {
            '& svg': {
                visibility: 'visible',
            },
            '& button': {
                visibility: 'visible',
            }
        },
    },

}));

const PublicVideoBox: FC<PublicVideoBoxProps> = ({ className, singlemedia, channelView, channel_id, ...rest }) => {

    const config = getWSConfig();
    const classes = useStyles();
    const [mediaToModal, setMediaToModal] = useState<Media>();
    const [isVideoPlayDialogOpen, setVideoPlayDialogOpen] = useState<boolean>(false);
    const [playerTime, setPlayerTime] = useState<number | null>(null);
    const [openVideoPasswordDialog, setOpenVideoPasswordDialog] = useState<boolean>(false);
    const [visibilityMode, setVisibilityMode] = useState<string>();

    const openModal = (singlemedia: Media): void => {
        setMediaToModal(singlemedia);
        setVideoPlayDialogOpen(true);
    };

    const getVideoAccess = (singlemedia: Media): void => {
        let params = { "kaltura_video_id": singlemedia.entry_id, "channel_id": channel_id }
        axios.post(process.env.REACT_APP_SERVER_URL + '/channel_video/1.0/check/visibility/by/kaltura/id', params, config)
            .then(function (response) {
                setVisibilityMode(response.data);
            })
            .catch(function (error) {
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    }
    const checkVideoAccess = (): void => {
        if (visibilityMode === "Public") {
            openModal(singlemedia);
        }
        else if (visibilityMode === "Password") {
            setOpenVideoPasswordDialog(true);
        }
    }

    const handleCloseVideoPlayDialog = (): void => {
        setVideoPlayDialogOpen(false);
    };

    const handleCaptionClick = (captionTime: string): void => {
        var timeSplit = captionTime.split(':');
        var seconds = (+timeSplit[0]) * 60 * 60 + (+timeSplit[1]) * 60 + (+timeSplit[2]);
        var miliseconds = seconds * 1000;
        setPlayerTime(miliseconds);
        openModal(singlemedia);
    }

    const handleOpenVideoIfCorrectPassword = (singlemedia: Media): void => {
        openModal(singlemedia);
    }

    useEffect(() => {
        getVideoAccess(singlemedia);
    }, []);

    return (
        <Card
            className={classes.root}
            raised={false}
        >
            <PublicVideoBoxInfo
                setMediaToModal={setMediaToModal}
                setVideoPlayDialogOpen={setVideoPlayDialogOpen}
                singlemedia={singlemedia}
                checkVideoAccess={checkVideoAccess}
                visibilityMode={visibilityMode}
            />

            {isVideoPlayDialogOpen &&
                <VideoPlayerDialog isOpen={isVideoPlayDialogOpen} onClose={handleCloseVideoPlayDialog}
                    video={mediaToModal} playerTime={playerTime} setPlayerTime={setPlayerTime} channelViewId={channelView} />
            }
            {openVideoPasswordDialog &&
                <VideoPasswordDialog
                    channel_id={channel_id}
                    isOpen={openVideoPasswordDialog}
                    setOpen={setOpenVideoPasswordDialog}
                    media={singlemedia}
                    handleOpenVideoIfCorrectPassword={() => handleOpenVideoIfCorrectPassword(singlemedia)}
                />
            }

            {singlemedia.captions && singlemedia.captions.length > 0 && (

                <TreeView

                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    disableSelection={true}

                >
                    <TreeItem className={classes.firstTreeitem} nodeId="-1" label={"Encontrado en " + singlemedia.captions.length + " subtítulos"}>
                        {singlemedia.captions.map((caption, index) => {
                            return (
                                <TreeItem
                                    className={classes.treeitems}
                                    key={index}
                                    nodeId={index.toString()}
                                    label={"[" + caption.startsAt + "]" + " " + caption.line}
                                    onClick={() => handleCaptionClick(caption.startsAt)}
                                />
                            )
                        })}
                    </TreeItem>
                </TreeView>
            )}
        </Card>
    );

};

PublicVideoBox.propTypes = {
    className: PropTypes.string,
};
export default PublicVideoBox;
