import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Box, Stack, Button, Typography, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import type { Media } from 'src/types/media';
import { getWSConfig } from 'src/views/utils/Utils';
import { ChannelView, ChannelViewTab } from 'src/types/channelView';
import MobilePublicVideoBox from './MobilePublicVideoBox';
import MobileTabDrawer from './MobileTabDrawer';
import { useIntl } from 'react-intl';
import MobileSubsectionDrawer from './MobileSubsectionDrawer';
import { Model } from 'src/types/model';
import { FileResource } from 'src/types/file_resource';
import { ExternalLinkResource } from 'src/types/external_link_resource';
import { Channel } from 'src/types/channel';
import MobileExternalLinkBox from './MobileExternalLinkBox';
import MobilePublicModelBox from './MobilePublicModelBox';
import MobileFileBox from './MobileFileBox';

interface MobileTabContentProps {
    tab: ChannelViewTab;
    player_uiConfid: string;
    query: string;
    channelView: ChannelView;
    channel: Channel;
    setContentLoaded: any
}
const useStyles = makeStyles((theme) => ({
    stack: {
        overflowX: 'scroll',
        marginTop: '1rem',
        paddingBottom: '1rem'
    },
    seeAll: {
        color: theme.palette.text.secondary,
        marginLeft: 'auto',
        paddingTop: '4px',
        paddingRight: 0
    }
}));

const MobileTabContent: FC<MobileTabContentProps> = ({ tab, player_uiConfid, query, channelView, channel, setContentLoaded }) => {
    const intl = useIntl();
    const classes = useStyles();
    const config = getWSConfig();
    const [media, setMedia] = useState<Media[]>();
    const [models, setModels] = useState<Model[]>([]);
    const [files, setFiles] = useState<FileResource[]>([]);
    const [externalLinks, setExternalLinks] = useState<ExternalLinkResource[]>([]);
    const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
    const [isSubsectionDrawerOpen, setSubsectionDrawerOpen] = useState<boolean>(false)
    const [loadingVideos, setLoadingVideos] = useState<boolean>(true);
    const [loadingModels, setLoadingModels] = useState<boolean>(true);
    const [loadingFiles, setLoadingFiles] = useState<boolean>(true);
    const [loadingExternalLinks, setLoadingExternalLinks] = useState<boolean>(true);

    const getTabContent = (): void => {
        let params = {};
        params["channel_view_tab_id"] = tab.channel_view_tab_id
        params["videos"] = true
        params["models"] = false
        if (query && query != '') params["search_term"] = query

        axios.post(process.env.REACT_APP_SERVER_URL + '/channel_view_tab/1.0/public/search/media', params, config)
            .then(function (response) {
                let data: Media[] = response.data;

                setMedia(data);
                setLoadingVideos(false);
                setContentLoaded(true);
            })
            .catch(function (error) {
                setLoadingVideos(false);
                console.log("error loading tab content from WS");
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            });
    };

    const openTabDrawer = (): void => {
        if (tab.channelViewSectionList.length == 1) {
            if (tab.channelViewSectionList[0].channelViewSubSectionList.length === 1) {
                if ((tab.channelViewSectionList[0].channelViewSubSectionList[0].name == "Default") && tab.channelViewSectionList[0].name == "Default") setSubsectionDrawerOpen(true)
                else setDrawerOpen(true)
            }
            else {
                setDrawerOpen(true)
            }
        } else setDrawerOpen(true)
    }

    const closeTabDrawer = (): void => {
        if (tab.channelViewSectionList.length == 1) {
            if (tab.channelViewSectionList[0].channelViewSubSectionList.length === 1) {
                if ((tab.channelViewSectionList[0].channelViewSubSectionList[0].name == "Default") && tab.channelViewSectionList[0].name == "Default") setSubsectionDrawerOpen(false)
                else setDrawerOpen(false)
            }
            else {
                setDrawerOpen(false)
            }
        } else setDrawerOpen(false)
    }

    const searchModelsSimple = (): void => {
        let modelsList: Model[] = [];

        if (tab.channel_view_tab_id !== undefined) {
            for (let section of tab.channelViewSectionList) {
                for (let subsection of section.channelViewSubSectionList) {
                    for (let element of subsection.channelViewSubSectionMode3DList) {
                        modelsList.push(element.model_3D_entity);
                    }
                }
            }
        }

        setModels(modelsList);
        setLoadingModels(false);
    }

    const searchFilesSimple = (): void => {
        let filesList: FileResource[] = [];
        if (tab.channel_view_tab_id !== undefined) {
            for (let section of tab.channelViewSectionList) {
                for (let subsection of section.channelViewSubSectionList) {
                    for (let element of subsection.channelViewSubSectionFileResourceList) {
                        filesList.push(element.fileResourceEntity);
                    }
                }
            }
        }
        setFiles(filesList);
        setLoadingFiles(false);
    }

    const searchExternalLinksSimple = (): void => {
        let externalLinksList: ExternalLinkResource[] = [];
        if (tab.channel_view_tab_id !== undefined) {
            for (let section of tab.channelViewSectionList) {
                for (let subsection of section.channelViewSubSectionList) {
                    for (let element of subsection.channelViewSubSectionExternalLinkResourceList) {
                        externalLinksList.push(element.externalLinkResourceEntity);
                    }
                }
            }
        }
        setExternalLinks(externalLinksList);
        setLoadingExternalLinks(false);
    }

    const searchContent = (): any => {
        setLoadingVideos(true);
        setLoadingModels(true);
        setLoadingFiles(true);
        setLoadingExternalLinks(true);

        getTabContent();
        searchModelsSimple();
        searchFilesSimple();
        searchExternalLinksSimple();
    }

    const applyFiltersForTitle = (item: Model | FileResource | ExternalLinkResource, query: string): Boolean => {
        let matches = true;
        if (query) {
            const properties = ['title'];
            let containsQuery = false;
            let query_norm;
            let model_norm;
            query_norm = query.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            properties.forEach((property) => {
                if (item[property] !== undefined) {
                    model_norm = item[property].normalize('NFD').replace(/[\u0300-\u036f]/g, "");
                    if (model_norm.toLowerCase().includes(query_norm.toLowerCase())) {
                        containsQuery = true;
                    }
                }
            });
            if (!containsQuery) {
                matches = false;
            }
        }
        return matches;
    }

    const applyModelsFilters = (models: Model[], query: string): Model[] => {
        return models.filter((model) => {
            let matches = applyFiltersForTitle(model, query);
            return matches;
        });
    };
    const applyFilesFilters = (files: FileResource[], query: string): FileResource[] => {
        return files.filter((file) => {
            let matches = applyFiltersForTitle(file, query);
            return matches;
        });
    };
    const applyExternalLinksFilters = (externalLinks: ExternalLinkResource[], query: string): ExternalLinkResource[] => {
        return externalLinks.filter((externalLink) => {
            let matches = applyFiltersForTitle(externalLink, query);
            return matches;
        });
    };

    const filteredModels = (models === null) ? [] : applyModelsFilters(models, query);
    const filteredFiles = (files === null) ? [] : applyFilesFilters(files, query);
    const filteredExternalLinks = (externalLinks === null) ? [] : applyExternalLinksFilters(externalLinks, query);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            searchContent()
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [tab, query]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        searchContent()
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    if (loadingVideos || loadingFiles || loadingModels || loadingExternalLinks) {
        return (
            <div style={{ overflow: 'hidden', marginLeft: '0rem', marginTop: '2.5rem', paddingBottom: '0.5rem', display: "flex", justifyContent: "center" }}>
                <CircularProgress size={50} />
            </div>
        );
    } else {
        return (
            <Box>
                <MobileTabDrawer channelView={channelView} channel={channel} tab={tab} isOpen={isDrawerOpen} onClose={closeTabDrawer} player_uiConfid={player_uiConfid}></MobileTabDrawer>
                <MobileSubsectionDrawer beforeName={channelView.channel_name} isOpen={isSubsectionDrawerOpen} tab={tab} media={media} onClose={closeTabDrawer} channel={channel} channelView={channelView} models={models} files={files} externalLinks={externalLinks}></MobileSubsectionDrawer>
                <Box display="flex">
                    <Typography variant="h5" color="textPrimary">
                        {tab.name}
                    </Typography>
                    <Button className={classes.seeAll} size="medium" onClick={() => openTabDrawer()}>
                        {intl.formatMessage({ id: 'channel.see_all' })}
                    </Button>
                </Box>
                <Stack direction="row" spacing={2} className={classes.stack}>
                    {media.map((mediaItem, index) => {
                        return (
                            <MobilePublicVideoBox key={index} channelView={channelView} singleMedia={mediaItem} player_uiConfid={player_uiConfid} />
                        );
                    })}

                    {/* Models rendering */}
                    {filteredModels.map((model, index) => {
                        return (
                            <MobilePublicModelBox key={index} singleModel={model} channel={channel} />
                        );
                    })}

                    {/* Files rendering */}
                    {filteredFiles.map((file, index) => {
                        return (
                            <MobileFileBox key={index} file={file} />
                        );
                    })}

                    {/* Links rendering */}
                    {filteredExternalLinks.map((link, index) => {
                        return (
                            <MobileExternalLinkBox key={index} external_link={link} />
                        );
                    })}
                </Stack>
            </Box>
        );
    }

};

export default MobileTabContent;