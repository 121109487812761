import React, { useState } from 'react';
import type { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { Channel } from 'src/types/channel';
import { ChannelView } from 'src/types/channelView';
import {
    Info as InfoIcon,
    Play as PlayIcon
} from 'react-feather';
import MobileLiveDrawer from './MobileLiveDrawer';
import MobileInformationDrawer from './MobileInformationDrawer';

interface MobilePublicHeaderProps {
    channelView: ChannelView
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
        },
        iconButton: {
            desplay: 'flex',
            flexDirection: 'column'
        },
        header: {
            marginTop: 'auto',
            marginBottom: 'auto'
        }
    }),
);

const MobilePublicHeader: FC<MobilePublicHeaderProps> = ({ channelView }) => {
    const classes = useStyles();
    const history = useHistory();
    const [isLiveDrawerOpen, setLiveDrawerOpen] = useState<boolean>(false);
    const [isInfoDrawerOpen, setInfoDrawerOpen] = useState<boolean>(false);

    const closeLiveDrawer = (): void => {
        setLiveDrawerOpen(false)
    }

    const openLiveDrawer = (): void => {
        setLiveDrawerOpen(true)
    }

    const closeInfoDrawer = (): void => {
        setInfoDrawerOpen(false)
    }

    const openInfoDrawer = (): void => {
        setInfoDrawerOpen(true)
    }


    return (
        <Box display="flex">
            <MobileLiveDrawer channelView={channelView} isOpen={isLiveDrawerOpen} onClose={closeLiveDrawer} liveEntryId={channelView.liveEntryId}></MobileLiveDrawer>
            <MobileInformationDrawer channelView={channelView} isOpen={isInfoDrawerOpen} onClose={closeInfoDrawer}></MobileInformationDrawer>
            <Box className={classes.header}>
                <Typography
                    variant="h3"
                    color="textPrimary"
                >
                    {channelView.channel_name}
                </Typography>

            </Box>
            <Box display="flex" marginLeft='auto'>
                <IconButton className={classes.iconButton} onClick={openInfoDrawer} disabled={!channelView.channelViewInfo}>
                    <InfoIcon></InfoIcon>
                    <Typography
                        variant="h6"
                        color="inherit"
                    >
                        Info
                    </Typography>
                </IconButton>
                <IconButton className={classes.iconButton} onClick={openLiveDrawer} disabled={!channelView.liveEntryId}>
                    <PlayIcon></PlayIcon>
                    <Typography
                        variant="h6"
                        color="inherit"
                    >
                        Live
                    </Typography>
                </IconButton>
            </Box>
        </Box>
    );
}

export default MobilePublicHeader;