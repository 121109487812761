import React, { useEffect, useState } from 'react';
import type { FC, ChangeEvent } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CardMedia from '@mui/material/CardMedia';
import Label from 'src/components/Label';
import { getWSConfig } from 'src/views/utils/Utils';
import axios from 'axios';

interface PublicLiveProps {
    liveEntryId: string;
}

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        marginTop: '1.5rem',
    },
    videocontainer: {
        width: "100%",
        height: '27rem',
        paddingBottom: '2.5rem',
        paddingTop: '2.5rem',
        paddingRight: '2.5rem',
        paddingLeft: '2.5rem',
    },
    content: {
        marginTop: '1.5rem',
        width: '15rem',
        justifyContent: 'right',
        marginRight: '2.5rem',
    },
    label: {
        marginBottom: '0.5rem',
        backgroundColor: '#FF3378',
        color: '#FFFFFF',
    },
    title: {
        fontSize: '16px',
        marginBottom: '1rem',

    },
    description: {
        marginBottom: '1rem',
    },
    media: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    aspectRatio16x9: {
        width: '100%',
        height: 0,
        paddingBottom: '56.25%',
        position: 'relative',
    }
}));

const PublicLive: FC<PublicLiveProps> = ({ liveEntryId }) => {
    const classes = useStyles();
    const config = getWSConfig();
    const [live, setLive] = useState<any>();

    const getLive = (): void => {
        let params = {};
        params['media_id'] = liveEntryId;
        axios.post(process.env.REACT_APP_SERVER_URL + '/media/1.0/get/media', params, config)
            .then(function (response) {
                if (response && response.data) {
                    console.log(response.data);
                    let data = response.data;
                    setLive(data);
                }
            })
            .catch(function (error) {
                console.log("error loading videos from WS");
                if (error && error.response && error.response.data) {
                    console.log(error.response.data);
                }
            })
    }

    useEffect(() => {
        if (liveEntryId !== undefined && liveEntryId !== "") {
            getLive();
        }
    }, [liveEntryId]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <Card
                className={classes.root}
            >
                <div className={classes.aspectRatio16x9}>
                    <CardMedia
                        className={classes.media}
                    >
                        <iframe
                            id="kaltura_player_1617182903"
                            src={"https://cdnapisec.kaltura.com/p/2420952/sp/242095200/embedIframeJs/uiconf_id/47439093/partner_id/2420952?iframeembed=true&playerId=kaltura_player_1617183208&entry_id=" + liveEntryId}
                            style={{ width: '100%', height: '100%', paddingBottom: '2.5rem', paddingTop: '2.5rem', paddingRight: '1.5rem', paddingLeft: '2.5rem' }}
                            allowFullScreen={true}
                            frameBorder="0"
                            itemProp="video"
                            itemScope
                            itemType="http://schema.org/VideoObject"
                        >
                        </iframe>
                    </CardMedia>
                </div>
                <CardContent
                    className={classes.content}
                >
                    <Label
                        className={classes.label}
                    >
                        live
                    </Label>
                    <Typography
                        variant="h6"
                        color="textPrimary"
                        className={classes.title}
                    >
                        {live && live["name"]}
                    </Typography>

                    <Typography
                        variant="body2"
                        color="textSecondary"
                        className={classes.description}
                    >
                        {live && live["description"]}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
};
export default PublicLive;