import React from 'react';
import type { FC } from 'react';
import { Box, SvgIcon, Link } from '@mui/material';

interface MobileInformationLinkProps {
    text: string;
    link: string;
    icon: any;
}

const MobileInformationLink: FC<MobileInformationLinkProps> = ({
    text,
    link,
    icon
}) => {
    return (
        <Box display="flex" gap={6}>
            <SvgIcon>
                {icon}
            </SvgIcon>
            <Link variant="body2" color="textPrimary" href={link}>
                {text}
            </Link>
        </Box>
    );
};

export default MobileInformationLink;